/* @flow */

import React from 'react';

import {svgIconWrapper} from '../svg-icon-wrapper';
import Draft from './draft-icon.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Draft {...validProps} />;
}
SvgComponent.iconName = 'draft';

export const DraftIcon = svgIconWrapper(SvgComponent);
