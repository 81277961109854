/* @flow */

import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Quote from './icon-quote.svg';

type Props = {
    fill?: string,
};
function SvgComponent(props: Props) {
    const {fill = 'white'} = props;
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Quote {...validProps} fill={fill} />;
}
SvgComponent.iconName = 'quote';

export const QuoteIcon = svgIconWrapper(SvgComponent);
