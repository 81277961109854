/* @flow */

import * as React from 'react';

import type {
    ReactionFragment as Reaction,
    SessionUser,
    CommentFragment as Comment,
    ReactionType,
    ChannelStatus,
    ThreadType,
} from 'nutshell-graphql-types';

import {colors} from 'shells/colors';
import {TimelineEntryIcon} from 'shells/timeline/gutter/timeline-entry-icon';
import type {
    EmailEngagementProps,
    ZoomMeetingDataProps,
    ThreadDataProps,
} from 'shells/timeline/entry/email-engagement-reaction-bar';
import {TimelineHeader, type TimelineHeaderProps} from 'shells/timeline/entry/header';
import type {TimelineCommentsProps} from 'shells/timeline/entry/comments';
import type {ToolbarOption} from 'shells/timeline/entry/toolbar/timeline-entry-toolbar';
import type {Reactions} from 'shells/timeline/reaction-bar/types';
import {TIMELINE_ENTRY_ICONS} from 'shells/timeline/gutter/consts';
import {useResizeObserver} from 'shells/utils/hooks';

import {getParticipantsForAvatarList} from '../helpers';
import {
    getCommentsWithFilteredReactions,
    getFilteredReactions,
    getToggleAction,
} from '../reactions/helpers';
import {useToggleReaction} from '../reactions/reaction-mutations';
import {TimelineEntryContext} from '../timeline';

import type {Props as TimelineEntryContentProps} from './timeline-entry-content';
import {TimelineEntryCard} from './timeline-entry-card';

import './timeline-entry.css';

type Props = {|
    id: string,
    iconVariant: any,
    // Header props
    ...TimelineHeaderProps,
    // Content props
    ...TimelineEntryContentProps,
    // Comment props
    ...TimelineCommentsProps,
    comments?: Comment[],
    reactions?: Reaction[],
    currentUser?: SessionUser,
    customBorderProps?: {
        hasDottedBorder?: boolean,
        borderColor?: string,
    },
    emailEngagement?: EmailEngagementProps,
    zoomMeetingData?: ZoomMeetingDataProps,
    threadData?: ThreadDataProps,
    disableComments?: boolean,
    disableReactions?: boolean,
    actionMenu?: React.Node,
    additionalToolbarOptions?: ToolbarOption[],
    onClickOpen?: (e: SyntheticEvent<*>) => void,
    isLastEntry?: boolean,
    isTimelineSmall?: boolean,
    hasGutterLine?: boolean,
    isPinnedThread?: boolean,
    hasActivityTranscription?: boolean,
    externalChannelStatus?: ?ChannelStatus,
    threadType?: ThreadType,
|};

export function TimelineEntry(props: Props) {
    const {
        id,
        iconVariant,
        entryType,
        changeType,
        timestamp,
        actor,
        subject,
        subjectTitle,
        content,
        truncatedContent,
        status,
        entity,
        participants,
        relatedLeads,
        metadata,
        reactions,
        comments,
        onAddComment,
        onDeleteComment,
        currentUser,
        customBorderProps,
        emailEngagement,
        zoomMeetingData,
        threadData,
        disableComments,
        disableReactions,
        actionMenu,
        additionalToolbarOptions,
        onClickOpen,
        isExpanded,
        pinnedInfo,
        numVisits,
        hasGutterLine,
        zoomTranscriptSummary,
        isPinnedThread,
        threadType,
        hasActivityTranscription,
    } = props;

    const timelineEntryRef = React.useRef();
    // TODO: Need to look into why this is needed for TimelineIcon resizing to work...
    // eslint-disable-next-line no-unused-vars
    const [height] = useResizeObserver(timelineEntryRef.current);
    const toggleReaction = useToggleReaction();

    // Get participants for avatar list for non-note entries
    const participantsForAvatarList =
        entryType !== 'Note'
            ? getParticipantsForAvatarList(participants, actor, entity)
            : undefined;
    // Get filtered reactions if those exist
    const filteredEntryReactions = reactions ? getFilteredReactions(reactions) : undefined;
    // Get comments with filtered reactions if those exist
    const commentsWithFilteredReactions = comments
        ? getCommentsWithFilteredReactions(comments)
        : undefined;
    // Define currentUserId if currentUser exists
    const currentUserId = currentUser ? currentUser.id : undefined;

    const onToggleReaction = currentUser
        ? (
              changeLogId: string,
              reactionType: ReactionType,
              filteredReactions: Reactions,
              parentChangeLogId?: string
          ) => {
              return toggleReaction({
                  changeLogId,
                  reactionType,
                  user: currentUser,
                  toggleAction: getToggleAction(reactionType, filteredReactions, currentUser.id),
                  parentChangeLogId,
              });
          }
        : undefined;

    const isLastEntry =
        Boolean(React.useContext(TimelineEntryContext).lastEntryId === id) || props.isLastEntry;
    const isTimelineSmall =
        Boolean(React.useContext(TimelineEntryContext).isTimelineSmall) || props.isTimelineSmall;

    const leftLineColor = colors[TIMELINE_ENTRY_ICONS[iconVariant].backgroundColor];

    return (
        <div
            styleName='timeline-entry'
            style={{marginBottom: isLastEntry ? '60px' : undefined}}
            ref={(ref) => {
                timelineEntryRef.current = ref;
            }}
        >
            <div
                styleName={
                    isTimelineSmall ? 'entry-header-container--small' : 'entry-header-container'
                }
            >
                <TimelineEntryIcon
                    variant={iconVariant}
                    gutterContainerRef={timelineEntryRef}
                    isSmall={isTimelineSmall}
                    isLastEntry={isLastEntry}
                    onClickOpen={onClickOpen}
                    hasGutterLine={hasGutterLine}
                />
                <TimelineHeader
                    timestamp={timestamp}
                    actor={actor}
                    entryType={entryType}
                    entity={entity}
                    participants={participants}
                    changeType={changeType}
                    isSmall={isTimelineSmall}
                    pinnedInfo={pinnedInfo}
                    numVisits={numVisits}
                    isPinnedThread={isPinnedThread}
                    threadType={threadType}
                    externalChannelStatus={props.externalChannelStatus}
                />
            </div>
            <div
                styleName={
                    isTimelineSmall ? 'timeline-entry-container--small' : 'timeline-entry-container'
                }
            >
                <TimelineEntryCard
                    changeLogId={id}
                    subject={subject}
                    subjectTitle={subjectTitle}
                    content={content}
                    truncatedContent={truncatedContent}
                    status={status}
                    participants={participantsForAvatarList}
                    relatedLeads={relatedLeads}
                    metadata={metadata}
                    filteredEntryReactions={filteredEntryReactions}
                    comments={commentsWithFilteredReactions}
                    currentUserId={currentUserId}
                    onToggleReaction={onToggleReaction}
                    onAddComment={onAddComment}
                    onDeleteComment={onDeleteComment}
                    onClickOpen={onClickOpen}
                    customBorderProps={customBorderProps}
                    emailEngagement={emailEngagement}
                    zoomMeetingData={zoomMeetingData}
                    threadData={threadData}
                    disableComments={disableComments}
                    disableReactions={disableReactions}
                    actionMenu={actionMenu}
                    additionalToolbarOptions={additionalToolbarOptions}
                    isExpanded={isExpanded}
                    leftLineColor={leftLineColor}
                    zoomTranscriptSummary={zoomTranscriptSummary}
                    hasActivityTranscription={hasActivityTranscription}
                />
            </div>
        </div>
    );
}
