/* @flow */

import {createAction} from 'redux-act';

import type {InstagramReportDataRequestedAction} from '../types';

/*
 * Action creators
 */
export const requestReportData: (
    payload: $PropertyType<InstagramReportDataRequestedAction, 'payload'>
) => InstagramReportDataRequestedAction = createAction('INSTAGRAM_REPORT_DATA_REQUESTED');
export const updateReportChartData = createAction('INSTAGRAM_REPORT_CHART_DATA_UPDATED');
export const updateReportMaps = createAction('INSTAGRAM_REPORT_MAPS_UPDATED');
export const failReportChartData = createAction('INSTAGRAM_REPORT_CHART_DATA_FAILED');
