/* @flow */

import * as React from 'react';

type Props = {
    size?: number,
};

export function PhoneSetup(props: Props) {
    return (
        <svg
            width={props.size}
            viewBox='0 0 212 148'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect
                width='134'
                height='254.6'
                rx='19'
                transform='matrix(-1 0 0 1 174.828 2)'
                fill='#fff'
                stroke='#4D4540'
                strokeWidth='5'
            />
            <path
                d='M130.789 4.218c.882-1.983-.57-4.218-2.741-4.218H87.444c-2.17 0-3.623 2.235-2.741 4.218l2.195 4.94a3 3 0 0 0 2.742 1.782h36.212a3 3 0 0 0 2.742-1.782z'
                fill='#4D4540'
            />
            <path
                d='M130.789 4.218c.882-1.983-.57-4.218-2.741-4.218H87.444c-2.17 0-3.623 2.235-2.741 4.218l2.195 4.94a3 3 0 0 0 2.742 1.782h36.212a3 3 0 0 0 2.742-1.782z'
                stroke='#4D4540'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M69.813 33.575c-7.18.055-12.994 5.92-12.986 13.1l.068 62.197a12.9 12.9 0 0 0 8.512 12.135v15.317c0 .993 1.29 1.379 1.836.549l9.953-15.156 68.184-.519c7.18-.055 12.993-5.919 12.986-13.099l-.069-62.198a12.897 12.897 0 0 0-13.013-12.9z'
                fill='#EBE8E8'
            />
            <path
                d='M112.27 60.154a1.682 1.682 0 0 1-1.582-1.987l1.157-6.064q.028-.138.03-.28c-.002-1.283-1.798-1.45-4.125-1.45s-4.124.167-4.124 1.45q.002.142.03.28l1.147 6.023a1.68 1.68 0 0 1-.35 1.369 1.69 1.69 0 0 1-1.27.62c-11.128.39-18.982 4.035-18.982 10.688v2.556a1.224 1.224 0 0 0 1.224 1.224h20.257c.802 0 1.224.59 1.224 1.224a1.124 1.124 0 0 1-.971 1.14l-15.023 2.907c-.592.085-.97.59-.97 1.182v5.149c0 9.874 7.722 18.188 17.808 18.188 9.875 0 17.851-8.018 17.851-17.85V75.76a1.19 1.19 0 0 1 .761-1.1 1.2 1.2 0 0 1 .463-.078h3.249a1.225 1.225 0 0 0 1.224-1.224v-2.556c0-6.67-7.855-10.053-19.028-10.65'
                fill='#FE5800'
            />
        </svg>
    );
}
