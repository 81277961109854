/* @flow */

import * as React from 'react';
import {Body} from 'shells/typography';
import {FooterBar} from 'shells/modal';
import {Button} from 'shells/button';
import {Link} from 'shells/link';
import {FeatureUsage} from 'shells/limits';

import './feature-limits-modal-content.css';

type Props = {
    currentPlanLimit: ?number,
    currentPlanName: string,
    currentUsage: number,
    humanReadableType: string,
    upgradePlanName: string,
    upgradePlanLimit: ?number,
    tierPlanLimit: ?number,
    displayString?: string,
    onClose: () => void,
};

export function FeatureLimitsModalContent(props: Props) {
    const verb = props.currentPlanLimit === 1 ? 'is' : 'are';

    const tierPlanLimitStr = props.tierPlanLimit ? props.tierPlanLimit.toString() : null;
    const currentPlanLimitStr = props.currentPlanLimit ? props.currentPlanLimit.toString() : null;

    return (
        <>
            <Body>
                {shouldShowCustomLimitMessage(props.tierPlanLimit, props.currentPlanLimit) ? (
                    <>
                        Your plan supports {tierPlanLimitStr || 'unlimited'}{' '}
                        {props.humanReadableType}, but you have been provided a custom limit of{' '}
                        {currentPlanLimitStr}.{' '}
                    </>
                ) : (
                    <>
                        {' '}
                        You’ve created {props.currentUsage} out of the{' '}
                        {props.currentPlanLimit || 'unlimited'} {props.displayString} that {verb}{' '}
                        included in your current plan, {props.currentPlanName}.{' '}
                    </>
                )}
                If you need to create more {props.humanReadableType} or access additional features,
                you can upgrade to {props.upgradePlanName} or explore our other plan options.
            </Body>
            <div styleName='feature-usage-container'>
                <FeatureUsage
                    currentUsage={props.currentUsage}
                    currentPlanMaxUsage={props.currentPlanLimit}
                    hasCustomLimit={shouldShowCustomLimitMessage(
                        props.tierPlanLimit,
                        props.currentPlanLimit
                    )}
                    nextPlanMaxUsage={props.upgradePlanLimit}
                />
            </div>
            <FooterBar
                left={
                    <Button size='big' onClick={props.onClose} variant='text-secondary'>
                        Not right now
                    </Button>
                }
                right={
                    <Link size='big' newTab={true} href='/setup/billing' variant='button-primary'>
                        See upgrade options
                    </Link>
                }
            />
        </>
    );
}

// If there is a custom limit and it is higher than the current tier plan limit, show the custom limit message
// If tierPLanLimit is null, the user is on a plan that doesn't have a limit so return false
function shouldShowCustomLimitMessage(tierPlanLimit: ?number, currentPlanLimit: ?number) {
    if (typeof tierPlanLimit === 'number' && typeof currentPlanLimit === 'number') {
        return tierPlanLimit < currentPlanLimit;
    }

    return false;
}
