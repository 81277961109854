/* @flow */

import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Messenger from './icon-messenger.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Messenger {...validProps} />;
}
SvgComponent.iconName = 'messenger-icon';

export const MessengerIcon = svgIconWrapper(SvgComponent);
