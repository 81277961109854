/* @flow */

import * as React from 'react';

import type {ThreadType} from 'nutshell-graphql-types';
import {formatTimestampForPinnedEntry} from 'nutshell-core/date-time/formatting';

import {Body} from '../../../typography';
import {SpacerGroup} from '../../../spacer/spacer-group';

import type {PinnedInfo} from './types';
import {TimelineHeaderEmailSource} from './timeline-header-email-source';

import {
    TimelineHeaderActionText,
    TimelineHeaderTimestamp,
    type TimelineHeaderActionTextProps,
} from './';

import './timeline-header.css';

// TimelineHeaderProps
export type Props = {|
    ...TimelineHeaderActionTextProps,
    timestamp: number,
    isSmall?: boolean,
    pinnedInfo?: ?PinnedInfo,
    isPinnedThread?: boolean,
    threadType?: ThreadType,
    subtitleComponent?: React.Node,
    originatedVia?: ?string,
|};

export function TimelineHeader(props: Props) {
    const {
        timestamp,
        isSmall,
        pinnedInfo,
        isPinnedThread,
        threadType,
        subtitleComponent,
        originatedVia,
        ...restProps
    } = props;

    return (
        <div styleName={isSmall ? 'header-container--small' : 'header-container'}>
            <SpacerGroup mb={isSmall ? 2 : 4}>
                <div className='flex align-center'>
                    {isPinnedThread && <Body size='small'>Most recent message:&nbsp;</Body>}
                    <TimelineHeaderTimestamp timestamp={timestamp} />
                    {originatedVia && <TimelineHeaderEmailSource originatedVia={originatedVia} />}
                    {pinnedInfo ? (
                        <div
                            styleName='pinned'
                            data-tip={
                                pinnedInfo.pinnedTime
                                    ? `Pinned ${formatTimestampForPinnedEntry(
                                          pinnedInfo.pinnedTime
                                      )}`
                                    : undefined
                            }
                        >
                            (Pinned by {pinnedInfo.pinnedByUser.name})
                        </div>
                    ) : undefined}
                </div>
            </SpacerGroup>
            <TimelineHeaderActionText
                {...restProps}
                isPinnedThread={isPinnedThread}
                threadType={threadType}
            />
            {subtitleComponent && <div className='mt-4 font-size-small'>{subtitleComponent}</div>}
        </div>
    );
}
