import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Send from './icon-send.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Send {...validProps} />;
}
SvgComponent.iconName = 'send';

export const SendIcon = svgIconWrapper(SvgComponent);
