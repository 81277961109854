/* @flow */

import * as React from 'react';

import {Dialog} from '../../dialog';

import {type DialogCopy} from './helpers';

type Props = {|
    anchor: HTMLElement,
    onPrimary: () => void,
    onSecondary: () => void,
    copy: DialogCopy,
|};

export function StatusDialog(props: Props) {
    return (
        <Dialog
            anchor={props.anchor}
            question={props.copy.question}
            primaryText={props.copy.primaryText}
            primaryVariant='save'
            secondaryText={props.copy.secondaryText}
            secondaryVariant='secondary'
            onPrimary={props.onPrimary}
            onSecondary={props.onSecondary}
            location='bottom'
            customStyles={{
                maxWidth: '240px',
            }}
        />
    );
}
