
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ZoomMeetingTimelineFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ZoomMeeting"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"topic"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"transcript"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"recordingUrl"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"recordingStatus"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"transcriptionSummary"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"EntitySummaryFragment"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"startTime"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"participants"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"durationMinutes"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":359}};
    doc.loc.source = {"body":"#import \"../../../power-ai/timeline-summary/graphql/fragments/entity-summary-fragment.graphql\"\n\nfragment ZoomMeetingTimelineFragment on ZoomMeeting {\n    id\n    topic\n    transcript\n    recordingUrl\n    recordingStatus\n    transcriptionSummary {\n        ...EntitySummaryFragment\n    }\n    startTime\n    participants {\n        name\n    }\n    durationMinutes\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../../../power-ai/timeline-summary/graphql/fragments/entity-summary-fragment.graphql").definitions));


      module.exports = doc;
    
