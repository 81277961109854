/* @flow */

import * as React from 'react';
import classnames from 'classnames';

import type {Color} from './body';

import './section-label.css';

type Props = {
    children?: React.Node,
    as: string,
    noBottomBorder?: boolean,
    noBottomMargin?: boolean,
    htmlFor?: string,
    size?: 'mini' | 'small' | 'normal',
    color?: Color,
};

SectionLabel.defaultProps = {
    as: 'div',
};

/*
 * This component is responsible for rendering a 12px all uppercased
 * section label that we use on peep and lead pages.
 */
export function SectionLabel({
    children,
    as: Tag,
    noBottomBorder,
    noBottomMargin,
    htmlFor,
    size = 'normal',
    color,
}: Props) {
    const styleNames = classnames('section-label', {
        'no-bottom-border': noBottomBorder,
        'no-bottom-margin': noBottomMargin,
        [size]: size,
        light: color === 'light',
        medium: color === 'medium',
        dark: color === 'dark',
        person: color === 'person',
        error: color === 'error',
        blue: color === 'blue',
        white: color === 'white',
        green: color === 'green',
        'blue-dk': color === 'blue-dk',
        'navy-dk': color === 'navy-dk',
        warn: color === 'warn',
    });

    return (
        <Tag styleName={styleNames} htmlFor={htmlFor}>
            {children}
        </Tag>
    );
}
