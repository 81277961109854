import React from 'react';

import {svgIconWrapper} from '../svg-icon-wrapper';
import ClipboardChecklist from './icon-clipboard-checklist.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <ClipboardChecklist {...validProps} />;
}
SvgComponent.iconName = 'clipboard-checklist';

export const ClipboardChecklistIcon = svgIconWrapper(SvgComponent);
