/* @flow */

import React from 'react';

type Props = {
    size: number,
};

export const InstagramColoredIcon = ({size = 14}: Props) => {
    return <img alt='Instagram icon' style={{width: size}} src='/include/images/instagram.png' />;
};
