/* @flow */

import * as React from 'react';

import {Header, Subheader} from 'shells/typography';
import {Field} from 'redux-form';
import {ErrorMessage} from 'shells/form';

import {TinyIntroTextField} from '../../fields/tiny-intro-text-field';
import {LOGIN_FORM_PASSWORD_FIELD} from '../login-form';

import {OptionsSection} from './options-section';
import {LoginFlowButton} from './login-flow-button';

type Props = {|
    handleSubmit: (formValues: Object) => void | Promise<*>,
    submitting: boolean,
    valid: boolean,
    emailValue: ?string,
    submitFailed: boolean,
    error: ?string,
|};

export function PasswordLoginPage(props: Props) {
    return (
        <form onSubmit={props.handleSubmit}>
            <Header>Welcome back!</Header>
            <Subheader>Signing in as {props.emailValue}</Subheader>
            <div className='mt-24'>
                <Field
                    key='password'
                    name={LOGIN_FORM_PASSWORD_FIELD}
                    placeholder='··········'
                    title='Password'
                    type='password'
                    autoFocus={true}
                    autocomplete={true}
                    component={TinyIntroTextField}
                />
            </div>
            <LoginFlowButton
                bttnVariant={props.valid ? 'primary' : 'secondary'}
                submitting={props.submitting}
            />
            {props.submitFailed && props.error && (
                <div className='my-16'>
                    <ErrorMessage>{props.error}</ErrorMessage>
                </div>
            )}
            <OptionsSection emailValue={props.emailValue} />
        </form>
    );
}
