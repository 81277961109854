/* @flow */

import * as React from 'react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

import type {QuoteDisplayStatus} from 'nutshell-graphql-types';
import type {QuoteTimes} from '../../../../../../ui/lead/quotes/helpers';

import {colors} from '../../colors';
import {useRefObserver} from '../../utils/hooks';
import {Body} from '../../typography';
import {UpgradeArrowIcon} from '../../icon';

import {StatusDialog} from './status-dialog';
import {
    getActiveStatus,
    getStatusLabelIcon,
    getBackgroundComponent,
    getDialogCopy,
    getLabelTooltip,
} from './helpers';

import './status-label.css';

const VARIANT_LABEL_MAP = {
    DRAFT: 'Draft',
    PUBLISHED: 'Ready',
    SENT: 'Sent',
    ACCEPTED: 'Accepted',
    ARCHIVED: 'Removed',
    REVOKED: 'Revoked',
    EXPIRED: 'Expired',
};

/**
 * Supports a subset of the QuoteDisplayStatus types, as not all display
 * statuses are valid status labels.
 */
export type StatusLabelVariant =
    | 'DRAFT'
    | 'PUBLISHED'
    | 'SENT'
    | 'ACCEPTED'
    | 'ARCHIVED'
    | 'REVOKED'
    | 'EXPIRED';

type Props = {|
    variant: StatusLabelVariant,
    quoteStatus: QuoteDisplayStatus,
    onAdvance: () => void,
    isDisabled: boolean,
    featureGated?: boolean,
    width?: number,
    height?: number,
    quoteTimes?: QuoteTimes,
|};

export function StatusLabel(props: Props) {
    const [isPopoverShowing, setIsPopoverShowing] = React.useState<boolean>(false);
    const statusLabelRef = React.useRef<HTMLElement | null>(null);
    const {isHovered} = useRefObserver(statusLabelRef, ['isHovered']);

    React.useEffect(() => {
        // Sometimes the tooltip doesn't show up on the first render
        // This is a workaround to force a rebuild
        ReactTooltip.rebuild();
    }, []);

    const handleClick = () => {
        if (props.featureGated) {
            window.location.href = '/sales/quote-upgrade';
        } else {
            setIsPopoverShowing(!isPopoverShowing);
        }
    };

    const isActive = getActiveStatus(props.variant, props.quoteStatus);
    const BackgroundComponent = getBackgroundComponent(props.variant);
    const copy = getDialogCopy(props.quoteStatus);

    const backgroundWrapperStyles = classNames({
        'background-wrapper--grey':
            props.quoteStatus === 'DRAFT' ||
            props.quoteStatus === 'ARCHIVED' ||
            props.quoteStatus === 'REVOKED',
        'background-wrapper--blue':
            props.quoteStatus === 'PUBLISHED' || props.quoteStatus === 'SENT',
        'background-wrapper--green': props.quoteStatus === 'ACCEPTED',
        'background-wrapper--disabled': !isActive,
        'background-wrapper': true,
    });

    return (
        <>
            <div
                onClick={props.isDisabled ? null : handleClick}
                styleName={classNames({
                    'status-label': true,
                })}
                style={{width: props.width, height: props.height}}
                ref={statusLabelRef}
                data-tip={
                    props.quoteTimes
                        ? getLabelTooltip(
                              props.variant,
                              props.quoteStatus,
                              props.quoteTimes,
                              props.featureGated
                          )
                        : ''
                }
            >
                <div
                    styleName={backgroundWrapperStyles}
                    style={{width: props.width, height: props.height}}
                >
                    <BackgroundComponent width={props.width} height={props.height} />
                </div>
                <div styleName='status-label-content'>
                    {getStatusLabelIcon(props.variant, isActive)}
                    <Body
                        size='small'
                        color={isActive ? 'white' : 'medium'}
                        underline={props.isDisabled ? false : isHovered || false}
                    >
                        {VARIANT_LABEL_MAP[props.variant]}
                    </Body>
                    {props.featureGated && props.variant !== 'DRAFT' && (
                        <UpgradeArrowIcon size={16} wrapWithDiv={false} color={colors.blue} />
                    )}
                </div>
            </div>
            {isPopoverShowing && statusLabelRef.current && (
                <StatusDialog
                    anchor={statusLabelRef.current}
                    copy={copy}
                    onPrimary={() => {
                        props.onAdvance();
                        setIsPopoverShowing(false);
                    }}
                    onSecondary={() => setIsPopoverShowing(false)}
                />
            )}
        </>
    );
}
