/* @flow */

import * as React from 'react';
import {Link as RoutedLink} from 'react-router-dom';

import {colors} from 'shells/colors';
import {Divider, Stack} from 'shells/layout';
import {Button} from 'shells/button';
import {Link} from 'shells/link';
import {WarningIcon} from 'shells/icon';
import {AiButtonContent} from '../ai-button-content';

import './power-ai-usage-popover.css';

type Props = {
    monthlyUsage: ?{
        limit: ?number,
        remaining: number,
    },
    shouldShowBillingLink: boolean,
    isTooltip: boolean,
    title: string,
    body: React.Node,
    limitsContent?: ?React.Node,
    linkContainerRef?: any,
    acknowledgment?: {
        onClick: () => void,
    },
    isRoutedLink?: boolean,
};

export function PowerAiUsagePopoverContent(props: Props) {
    const {isRoutedLink = true} = props;

    const acknowledgmentButton = props.acknowledgment ? (
        <Button
            variant='text-primary'
            size='small'
            onClick={props.acknowledgment.onClick}
            noPadding={true}
        >
            Got it
        </Button>
    ) : undefined;

    const billingLink =
        props.shouldShowBillingLink && !props.isTooltip ? (
            <div ref={props.linkContainerRef}>
                <Link
                    variant='button-ai-blue'
                    as={isRoutedLink ? RoutedLink : 'a'}
                    to='/setup/billing'
                    href='/setup/billing'
                    newTab={true}
                    isFullWidth={true}
                >
                    <div className='flex justify-center'>
                        <AiButtonContent
                            buttonText={
                                props.monthlyUsage && props.monthlyUsage.remaining <= 0
                                    ? 'Upgrade plan'
                                    : 'View plan'
                            }
                            buttonRef={props.linkContainerRef}
                        />
                    </div>
                </Link>
            </div>
        ) : undefined;

    return (
        <div styleName='power-ai-usage-popover-content'>
            {props.monthlyUsage && props.monthlyUsage.remaining <= 0 && (
                <div styleName='no-credits'>
                    <WarningIcon size={15} wrapWithDiv={false} color={colors.orange} />
                    <div>No credits left</div>
                </div>
            )}
            <Stack spacing={12}>
                <div styleName='title'>{props.title}</div>
                <div>{props.body}</div>
                <Divider variant='line--horizontal' />
                {props.limitsContent}
                {acknowledgmentButton && billingLink ? (
                    <div className='flex justify-sb'>
                        {acknowledgmentButton}
                        {billingLink}
                    </div>
                ) : (
                    <>{billingLink || acknowledgmentButton}</>
                )}
            </Stack>
        </div>
    );
}
