/* @flow */

import * as React from 'react';
import classnames from 'classnames';

import './page-wrapper.css';

type Props = {
    children?: React.Node,
    header?: React.Node,
    sidebar?: React.Node,
    pageCanScroll?: boolean,
    minWidth?: number,
    flashBanner?: React.Node,
    isEmbedded?: boolean,
    borderTopLeftColor?: 'primary' | 'secondary',
};

// $FlowIgnore ReactContext not supported
export const PageWrapperContext = React.createContext('pageContainer');

/*
This component provides a consistent outer container to any
page layout. It should wrap the entire content of the page
 */
export function PageWrapper(props: Props) {
    return (
        <div
            styleName={classnames({
                'page-container-scrolling-sidebar': props.pageCanScroll && props.sidebar,
                'page-container-fixed-sidebar': !props.pageCanScroll && props.sidebar,
                'page-container-no-sidebar': !props.sidebar,
                'page-container-with-navbar':
                    typeof NutClientConfig !== 'undefined' && NutClientConfig.hasUpdatedNavigation,
                'page-container-border-top-left-primary': props.borderTopLeftColor === 'primary',
                'page-container-border-top-left-secondary':
                    props.borderTopLeftColor === 'secondary',
                'page-container-embedded': props.isEmbedded,
            })}
        >
            {props.sidebar}
            <div key='main-content' styleName='page-container'>
                {props.header ? props.header : null}
                <main
                    styleName='main'
                    style={props.minWidth ? {minWidth: props.minWidth} : undefined}
                >
                    <PageWrapperContext.Provider value={{flashBanner: props.flashBanner}}>
                        {props.children}
                    </PageWrapperContext.Provider>
                </main>
            </div>
        </div>
    );
}
