/* @flow */

import * as React from 'react';

import {Header, Subheader} from 'shells/typography';
import {Field} from 'redux-form';
import {ErrorMessage} from 'shells/form';

import {TinyIntroTextField} from '../../fields/tiny-intro-text-field';
import {LOGIN_FORM_USERNAME_FIELD} from '../login-form';

import {OauthSection} from './oauth-section';
import {SignupSection} from './signup-section';
import {LoginFlowButton} from './login-flow-button';

type Props = {|
    alternateMessage: any,
    handleSubmit: () => any,
    submitting: boolean,
    valid: boolean,
    emailValue: ?string,
    submitFailed: boolean,
    error: ?string,
    onGoogleSubmit: (string) => Promise<*>,
    onMicrosoftSubmit: (?string) => Promise<*>,
    onMicrosoftIdentityFailure: (?Error) => void,
    // If Google rejects the identity of the user,
    // i.e. before we even try to authenticate with Nutshell
    onGoogleIdentityFailure: () => void,
    googleErrorMessage: ?string,
    microsoftErrorMessage: ?string,
    microsoftOauthUrl: ?string,
|};

export function SignInPage(props: Props) {
    return (
        <>
            <form onSubmit={props.handleSubmit}>
                {props.alternateMessage || (
                    <>
                        <Header>Welcome back!</Header>
                        <Subheader>Please log in to continue.</Subheader>
                    </>
                )}
                <OauthSection
                    onGoogleSubmit={props.onGoogleSubmit}
                    onMicrosoftSubmit={props.onMicrosoftSubmit}
                    onMicrosoftIdentityFailure={props.onMicrosoftIdentityFailure}
                    onGoogleIdentityFailure={props.onGoogleIdentityFailure}
                    googleErrorMessage={props.googleErrorMessage}
                    microsoftErrorMessage={props.microsoftErrorMessage}
                    microsoftOauthUrl={props.microsoftOauthUrl}
                />
                <div className='mt-24'>
                    <Field
                        key='email'
                        name={LOGIN_FORM_USERNAME_FIELD}
                        placeholder='john.appleseed@nutshell.com'
                        title='Email'
                        type='email'
                        autoFocus={true}
                        autocomplete={true}
                        component={TinyIntroTextField}
                    />
                </div>
                <LoginFlowButton
                    bttnVariant={props.valid ? 'primary' : 'secondary'}
                    submitting={props.submitting}
                />
                {props.submitFailed && props.error && (
                    <div className='my-16'>
                        <ErrorMessage>{props.error}</ErrorMessage>
                    </div>
                )}
            </form>
            <SignupSection emailValue={props.emailValue} />
        </>
    );
}
