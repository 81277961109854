/* @flow */

import * as React from 'react';

import {type ListOptionWithCount} from 'shells/select-option-components/option-with-count';
import {useGetAudiences} from './hooks/use-get-audiences';

type Props = {|
    children: (ChildrenProps) => React.Node,
|};

type ChildrenProps = {
    options: ListOptionWithCount[],
    isLoading: boolean,
    refetch: () => Promise<*>,
};

export const GraphQLSelectAudiencesProvider = (props: Props) => {
    const {audiences, refetch, isLoading} = useGetAudiences();

    // map over the list to get the proper value/label options to pass
    // through to the select component below
    const audienceOptions: ListOptionWithCount[] = audiences.map((node) => ({
        value: node.id,
        label: node.name,
        count: node.membershipStats.totalCount.value,
        isFx: node.isWebFX,
        isSyncing: node.isSyncing,
    }));

    return props.children({
        options: audienceOptions,
        isLoading,
        refetch,
    });
};
