/* @flow */

import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import EyeClosed from './icon-eye-closed.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <EyeClosed {...validProps} />;
}
SvgComponent.iconName = 'eyeClosed';

export const EyeClosedIcon = svgIconWrapper(SvgComponent);
