/* @flow */

import * as React from 'react';
import classnames from 'classnames';
import {RadioGroup, type RadioGroupProps} from './radio-group';
import './radio-group-slider.css';

type Props = {|
    ...RadioGroupProps,
    selectedIndex: number,
|};

export class RadioGroupSlider extends React.Component<Props> {
    static defaultProps = {
        size: 'normal',
    };

    render() {
        const {size, selectedIndex, ...radioGroupProps} = this.props;
        const styleNames = classnames('radio-group-slider', {
            'radio-group-slider--selected-0': selectedIndex === 0,
            'radio-group-slider--selected-1': selectedIndex === 1,
            'radio-group-slider--selected-2': selectedIndex === 2,
            'radio-group-slider--two-items':
                this.props.children.filter((child) => Boolean(child)).length === 2,
            'radio-group-slider--three-items':
                this.props.children.filter((child) => Boolean(child)).length === 3,
            'radio-group-slider--big': size === 'big',
        });

        return (
            <div styleName={styleNames}>
                <RadioGroup {...radioGroupProps} inline={true} />
            </div>
        );
    }
}
