/* @flow */

import * as React from 'react';

export type Props = {|
    width?: number,
    height?: number,
|};

export function Chevron(props: Props) {
    return (
        <svg
            width={props.width}
            height={props.height}
            viewBox='0 0 102 32'
            fill='currentColor'
            xmlns='http://www.w3.org/2000/svg'
            preserveAspectRatio='none'
        >
            <path d='M0 3C0 1.34315 1.34315 0 3 0H88.5368C89.4603 0 90.3323 0.42533 90.9008 1.15307L101.057 14.1531C101.905 15.2384 101.905 16.7616 101.057 17.8469L90.9008 30.8469C90.3323 31.5747 89.4603 32 88.5368 32H3C1.34315 32 0 30.6569 0 29V3Z' />
        </svg>
    );
}
