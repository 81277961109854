/* @flow */

import * as React from 'react';
import {Link as RouterLink} from 'react-router-dom';

import {Link} from 'shells/link';

import '../login-form.css';

type Props = {|
    emailValue: ?string,
|};

export function SignupSection(props: Props) {
    return (
        <div styleName='login-container'>
            Don’t have an account?{' '}
            <Link
                as={RouterLink}
                variant='primary'
                size='normal'
                to={{
                    pathname: '/signup',
                    search: props.emailValue ? `?email=${props.emailValue}` : undefined,
                }}
            >
                Sign up now
            </Link>
        </div>
    );
}
