/* @flow */

import * as React from 'react';
import {Field} from 'redux-form';
import {Link as RouterLink} from 'react-router-dom';

import {Link} from 'shells/link';

import {TinyIntroCheckbox} from '../../fields/tiny-intro-checkbox';
import {LOGIN_FORM_REMEMBER_ME_FIELD} from '../login-form';

import '../login-form.css';

type Props = {|
    emailValue: ?string,
|};

export function OptionsSection(props: Props) {
    return (
        <div styleName='label'>
            <Field
                key='remember_me'
                name={LOGIN_FORM_REMEMBER_ME_FIELD}
                title='Stay logged in?'
                component={TinyIntroCheckbox}
            />
            <Link
                as={RouterLink}
                to={{
                    pathname: '/auth/forgot-password',
                    search: props.emailValue ? `?email=${props.emailValue}` : undefined,
                }}
                variant='primary'
            >
                Forgot password?
            </Link>
        </div>
    );
}
