/* @flow */

import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Instagram from './icon-instagram.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Instagram {...validProps} />;
}
SvgComponent.iconName = 'instagram-icon';

export const InstagramIcon = svgIconWrapper(SvgComponent);
