/* @flow */

import * as React from 'react';
import classnames from 'classnames';

import './sidebar-user-avatar.css';

type Props = {
    avatarUrl: string,
    initials: string,
    size: number,
    isActive?: boolean,
    className?: string,
    isOnline?: ?boolean,
    hasBorder?: boolean,
};

export function SidebarUserAvatar(props: Props) {
    const style = {
        width: `${props.size}px`,
        height: `${props.size}px`,
        lineHeight: `${props.size}px`,
        fontSize: Math.floor(props.size / 2 - props.size / 15),
        boxShadow: 'none',
        flexShrink: 0,
        borderRadius: `${props.size}px`,
    };

    const styleName = classnames(props.isActive ? 'container-active' : 'container', {
        'has-border': props.hasBorder,
    });

    return (
        <div styleName={styleName} className={props.className} style={style}>
            {props.avatarUrl ? (
                <img style={style} src={`${props.avatarUrl}?width=100&height=100`} />
            ) : undefined}
            <div style={style} styleName='initials'>
                {props.initials}
            </div>
            {typeof props.isOnline === 'boolean' && (
                <div styleName={`status-indicator ${props.isOnline ? 'online' : 'offline'}`} />
            )}
        </div>
    );
}
