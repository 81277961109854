/* @flow */

import * as React from 'react';

import {Header, Subheader} from 'shells/typography';

import type {LoginVariant} from '../login-page';

import {LoginFlowButton} from './login-flow-button';

import '../login-form.css';

type Props = {|
    emailValue: ?string,
    ssoRedirectUrl: ?string,
    setVariant: (variant: LoginVariant) => void,
|};

export function ChooseMethodPage(props: Props) {
    return (
        <>
            <Header>Welcome back!</Header>
            <Subheader>Signing in as {props.emailValue}</Subheader>
            <div className='flex flex-dir-col gap-0'>
                <LoginFlowButton
                    bttnVariant='primary'
                    onClick={() => {
                        window.location.replace(props.ssoRedirectUrl);
                    }}
                >
                    Continue with SSO
                </LoginFlowButton>
                <div styleName='link-button'>
                    <LoginFlowButton
                        bttnVariant='text-primary'
                        onClick={() => {
                            props.setVariant('password');
                        }}
                    >
                        Continue with password
                    </LoginFlowButton>
                </div>
            </div>
        </>
    );
}
