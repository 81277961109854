/* @flow */

import * as React from 'react';

import {Header, Subheader} from 'shells/typography';
import {Button} from 'shells/button';
import {MfaCode} from 'shells/mfa/mfa-code';
import {NavButtons} from 'shells/modal/step-modal/layout';
import {Link} from 'shells/link';

import type {GetMfaApiResponse} from '../../../setup/mfa/types';
import {TYPE_RECOVERY, TYPE_SMS, TYPE_APP} from '../../../setup/mfa/constants';
import {mfa, getMfa} from '../../utils';

import '../login-form.css';

type Step = 'code' | 'recovery';

type Props = {
    onSubmit: (formValues: Object) => void | Promise<*>,
    mfaType?: string,
    onBack: () => void,
};

export function MFACodeForm(props: Props) {
    const [step, setStep] = React.useState<Step>('code');
    const [mfaValue, setMfaValue] = React.useState('');
    const [codeIsResent, setCodeIsResent] = React.useState(false);
    const {mfaType} = props;

    React.useEffect(() => {
        getMfa().then((result: GetMfaApiResponse) => {
            if (mfaType === 'phone' && result.result && result.result.value) {
                setMfaValue(result.result.value);
            }
        });
    }, [mfaType, setMfaValue]);

    const handleVerifyCode = (code: string, type: string): Promise<*> => {
        return new Promise(function (resolve, reject) {
            mfa({
                code: code,
                type: type,
            }).then((result) => {
                if (result.redirected) {
                    // Success, will redirect
                    document.location.href = result.url;
                    resolve();
                } else {
                    // Invalid code
                    result.json().then((body) => {
                        reject(body);
                    });
                }
            });
        });
    };

    if (step === 'code') {
        return (
            <form onSubmit={props.onSubmit}>
                <Header>Two-factor authentication</Header>
                <Subheader>
                    {props.mfaType === 'phone'
                        ? `We texted your code to your phone number ending in ${mfaValue}`
                        : 'Generate a code with your authenticator app'}
                </Subheader>

                <div className='mt-24'>
                    <MfaCode
                        onVerifyMfaCode={(code) =>
                            handleVerifyCode(code, mfaType === 'phone' ? TYPE_SMS : TYPE_APP)
                        }
                        inputCount={6}
                    />
                </div>

                {props.mfaType === 'phone' ? (
                    <div styleName='resend'>
                        Didn’t get a code?&nbsp;
                        <div>
                            {codeIsResent ? (
                                <div>Sent!</div>
                            ) : (
                                <Button
                                    variant='text-primary'
                                    disabled={codeIsResent}
                                    size='normal'
                                    onClick={() => {
                                        setCodeIsResent(true);

                                        Nut.ajax.post({
                                            url: '/auth/mfa-resend-phone-code',
                                        });

                                        // Re-enable button after 5 seconds
                                        const timer = setTimeout(() => {
                                            setCodeIsResent(false);
                                        }, 5000);

                                        return () => clearTimeout(timer);
                                    }}
                                >
                                    Send a new one
                                </Button>
                            )}
                        </div>
                    </div>
                ) : null}

                <div styleName='recovery-button-container'>
                    <Button
                        size='normal'
                        onClick={() => {
                            setStep('recovery');
                        }}
                    >
                        Use Recovery Code
                    </Button>
                </div>

                <NavButtons style={{justifyContent: 'flex-start', margin: 0}}>
                    <Link
                        variant='primary'
                        size='big'
                        onClick={() => {
                            props.onBack();
                        }}
                    >
                        Back
                    </Link>
                </NavButtons>
            </form>
        );
    } else if (step === 'recovery') {
        return (
            <>
                <Header>Recovery code</Header>
                <Subheader>Enter one of your recovery codes to verify your identity</Subheader>

                <div className='mt-24'>
                    <MfaCode
                        onVerifyMfaCode={(code) => handleVerifyCode(code, TYPE_RECOVERY)}
                        inputCount={6}
                    />
                </div>

                <NavButtons style={{justifyContent: 'flex-start'}}>
                    <Link
                        variant='primary'
                        size='big'
                        onClick={() => {
                            setStep('code');
                        }}
                    >
                        Back
                    </Link>
                </NavButtons>
            </>
        );
    } else {
        return null;
    }
}
