/* @flow */
import * as React from 'react';
import moment from 'moment';

import {getTimezoneAbbreviation} from 'nutshell-core/date-time/get-timezone-abbreviation';
import {Timestamp} from './timestamp';

const FORMATS = {
    time: 'h:mm A',
    timeWithDate: 'h:mm A [on] MMM D',
    timeWithDateYear: 'h:mm A [on] MMM D, YYYY',
    dayWithTime: 'dddd[,] h:mm A',
    shortMonthDay: 'MMM DD',
    shortMonthDayWithTime: 'MMM Do, h:mm a',
    longDateWithDay: 'dddd MMM Do, YYYY',
    monthDay: 'MMM Do',
    longMonthDay: 'MMMM Do',
    longMonthDayWithYear: 'MMMM Do, YYYY',
    longMonthDayYearWithTime: 'MMMM Do, YYYY h:mm A',
    dateWithSlashes: 'L',
    longDateWithTime: 'LLLL',
    longDayMonthWithAtTime: 'dddd, MMMM Do [at] h:mm A',
    longDayMonthYearWithAtTime: 'dddd, MMMM Do, YYYY, [at] h:mm A',
    shortDayMonthWithAtTime: 'MMMM Do [at] h:mm A',
    shortDayMonthYearWithAtTime: 'MMM Do, YYYY [at] h:mm A',
};

type Props = {
    datetime: number,
    format: $Values<typeof FORMATS>,
    addTimezoneAbbreviation?: boolean,
    className?: string,
};

FormattedTimestamp.formats = FORMATS;

export function FormattedTimestamp(props: Props) {
    const {datetime, format} = props;

    const datetimeValue = moment.unix(datetime);

    let timestamp = datetimeValue.isValid() ? datetimeValue.format(format) : '';

    if (props.addTimezoneAbbreviation) {
        timestamp = addTimezoneAbbreviation(timestamp, datetime);
    }

    return <Timestamp {...props} timestamp={timestamp} />;
}

const addTimezoneAbbreviation = (timestamp: string, datetime: number) => {
    const timezone = getTimezoneAbbreviation(datetime);

    if (timezone) {
        return `${timestamp} ${timezone}`;
    } else {
        return timestamp;
    }
};
