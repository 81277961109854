/* @flow */

import * as React from 'react';
import moment from 'moment';

import type {QuoteDisplayStatus} from 'nutshell-graphql-types';
import type {QuoteTimes} from '../../../../../../ui/lead/quotes/helpers';

import {
    AcceptedIcon,
    ArchiveIcon,
    CircleMinusIcon,
    DocumentReadyIcon,
    DraftIcon,
    SendIcon,
} from '../../icon';

import type {StatusLabelVariant} from './status-label';
import {Chevron, type Props as BackgroundComponentProps} from './chevron';
import {Rectangle} from './rectangle';

type ButtonConfig = {
    variant: StatusLabelVariant,
    onAdvance: () => void,
    disabled: boolean,
};

export function buildButtonConfig(
    quoteStatus: QuoteDisplayStatus,
    handleUpdateStatus: (status: QuoteDisplayStatus) => void
): ButtonConfig[] {
    switch (quoteStatus) {
        case 'DRAFT':
        case 'PUBLISHED':
        case 'SENT':
        case 'ACCEPTED': {
            const buttonConfig = [
                {
                    variant: 'DRAFT',
                    onAdvance: () => {},
                    disabled: getButtonDisabledFromStatus('DRAFT', quoteStatus),
                },
                {
                    variant: 'PUBLISHED',
                    onAdvance: () => handleUpdateStatus('PUBLISHED'),
                    disabled: getButtonDisabledFromStatus('PUBLISHED', quoteStatus),
                },
                {
                    variant: 'ACCEPTED',
                    onAdvance: () => handleUpdateStatus('ACCEPTED'),
                    disabled: getButtonDisabledFromStatus('ACCEPTED', quoteStatus),
                },
            ];

            if (NutClientConfig.hasQuotesFollowup) {
                buttonConfig.splice(2, 0, {
                    variant: 'SENT',
                    onAdvance: () => handleUpdateStatus('SENT'),
                    disabled: getButtonDisabledFromStatus('SENT', quoteStatus),
                });
            }

            return buttonConfig;
        }
        case 'ARCHIVED':
            return [
                {
                    variant: 'ARCHIVED',
                    onAdvance: () => {},
                    disabled: true,
                },
            ];
        case 'REVOKED':
            return [
                {
                    variant: 'REVOKED',
                    onAdvance: () => {},
                    disabled: true,
                },
            ];
        case 'EXPIRED':
            return [
                {
                    variant: 'EXPIRED',
                    onAdvance: () => {},
                    disabled: true,
                },
            ];
        default:
            return [];
    }
}

/**
 * Returns true if the button variant should be disabled based on the status of
 * the quote. This Prevents the user from advancing the quote to a status that
 * is not allowed.
 */
function getButtonDisabledFromStatus(
    variant: StatusLabelVariant,
    status: QuoteDisplayStatus
): boolean {
    switch (variant) {
        case 'DRAFT':
            return true;
        case 'PUBLISHED':
            return status !== 'DRAFT';
        case 'SENT':
            return status !== 'PUBLISHED';
        case 'ACCEPTED':
            if (NutClientConfig.hasQuotesFollowup) {
                return status !== 'SENT';
            }

            return status !== 'PUBLISHED';
        default:
            return false;
    }
}

/**
 * Returns true if the button variant should be active based on the status of
 * the quote. This is purely visual and does not affect the functionality of the
 * button.
 */
export function getActiveStatus(variant: StatusLabelVariant, status: QuoteDisplayStatus): boolean {
    switch (variant) {
        case 'DRAFT':
            return true;
        case 'PUBLISHED':
            return status === 'PUBLISHED' || status === 'SENT' || status === 'ACCEPTED';
        case 'SENT':
            return status === 'SENT' || status === 'ACCEPTED';
        case 'ACCEPTED':
            return status === 'ACCEPTED';
        default:
            return true;
    }
}

// TODO: Also change the color of the icon depending on the active status of the button (text should match)
export const getStatusLabelIcon = (variant: StatusLabelVariant, isActive: boolean) => {
    const iconColor = isActive ? 'white' : 'grey-lt';
    switch (variant) {
        case 'PUBLISHED':
            return <DocumentReadyIcon alignCenter={true} size={17} color={iconColor} />;
        case 'SENT':
            return <SendIcon alignCenter={true} size={15} color={iconColor} />;
        case 'ACCEPTED':
            return <AcceptedIcon alignCenter={true} size={17} color={iconColor} />;
        case 'ARCHIVED':
            return <ArchiveIcon alignCenter={true} size={17} color={iconColor} />;
        case 'REVOKED':
            return <CircleMinusIcon alignCenter={true} size={17} color={iconColor} />;
        case 'DRAFT':
        default:
            return <DraftIcon alignCenter={true} size={17} color={iconColor} wrapWithDiv={false} />;
    }
};

export function getBackgroundComponent(
    variant: StatusLabelVariant
): React.ComponentType<BackgroundComponentProps> {
    switch (variant) {
        case 'DRAFT':
        case 'PUBLISHED':
        case 'SENT':
            return Chevron;
        default:
            return Rectangle;
    }
}

export type DialogCopy = {|
    question: string,
    primaryText: string,
    secondaryText: string,
|};

const TO_READY_DIALOGUE_COPY: DialogCopy = {
    question: 'This quote will be locked in and available to send to your customers. Are you sure?',
    primaryText: 'Mark as ready',
    secondaryText: 'Keep as draft',
};

const TO_SENT_DIALOGUE_COPY: DialogCopy = {
    question: 'Mark this quote as sent to your lead?',
    primaryText: 'Mark as sent',
    secondaryText: 'Keep as ready',
};

const FROM_READY_TO_ACCEPTED_DIALOGUE_COPY: DialogCopy = {
    question: 'Finalize this quote by marking it as accepted by your customer?',
    primaryText: 'Mark accepted',
    secondaryText: 'Keep as ready',
};

const TO_ACCEPTED_DIALOGUE_COPY: DialogCopy = {
    question: 'Finalize this quote by marking it as accepted by your customer?',
    primaryText: 'Mark accepted',
    secondaryText: 'Keep as sent',
};

const DEFAULT_DIALOGUE_COPY: DialogCopy = {
    question: 'Advance to this status?',
    primaryText: 'Advance',
    secondaryText: 'Cancel',
};

export function getDialogCopy(status: QuoteDisplayStatus): DialogCopy {
    switch (status) {
        case 'DRAFT':
            return TO_READY_DIALOGUE_COPY;
        case 'PUBLISHED': {
            if (NutClientConfig.hasQuotesFollowup) {
                return TO_SENT_DIALOGUE_COPY;
            }

            return FROM_READY_TO_ACCEPTED_DIALOGUE_COPY;
        }
        case 'SENT':
            return TO_ACCEPTED_DIALOGUE_COPY;
        case 'ACCEPTED':
            return DEFAULT_DIALOGUE_COPY;
        default:
            return DEFAULT_DIALOGUE_COPY;
    }
}

function formatTime(datetime: number): string {
    const datetimeValue = moment.unix(datetime);

    return datetimeValue.isValid() ? datetimeValue.format('MMM Do, YYYY') : '';
}

function getStatusTime(variant: StatusLabelVariant, quoteTimes: QuoteTimes): string {
    switch (variant) {
        case 'DRAFT':
            return `Drafted ${formatTime(quoteTimes.createdAt)}`;
        case 'PUBLISHED':
            if (quoteTimes.publishedAt > 0) {
                return `Ready ${formatTime(quoteTimes.publishedAt)}`;
            } else {
                return '';
            }
        case 'SENT':
            if (quoteTimes.sentAt > 0) {
                return `Sent ${formatTime(quoteTimes.sentAt)}`;
            } else {
                return '';
            }
        case 'ACCEPTED':
            if (quoteTimes.acceptedAt > 0) {
                return `Accepted ${formatTime(quoteTimes.acceptedAt)}`;
            } else {
                return '';
            }
        default:
            return '';
    }
}

export function getLabelTooltip(
    variant: StatusLabelVariant,
    status: QuoteDisplayStatus,
    quoteTimes: QuoteTimes,
    featureGated?: boolean
): string {
    if (featureGated && status === 'DRAFT' && variant !== 'DRAFT') {
        return 'Upgrade to Nutshell Quotes';
    }

    return getStatusTime(variant, quoteTimes);
}
