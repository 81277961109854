/* @flow */

import * as React from 'react';

import type {EventFragmentSparse} from 'nutshell-graphql-types';
import {getApiTypeFromGraphQLTypename} from 'nutshell-core/utils';

import {Body} from 'shells/typography';
import {Stack} from 'shells/layout';

import {OpenableModals, useOpenableModal} from '../../openable-modals';

import {TimelineEntry} from './timeline-entry';

import './timeline-entry-content.css';

type Props = {
    event: EventFragmentSparse,
    entityPageId: ?string,
    entityPageName: ?string,
    isPinnedThread?: boolean,
};

export function TimelineEntryInboxThread(props: Props) {
    const {id: changeLogId, changeType, changeTime, actor, payload} = props.event;

    const threadId = payload.__typename === 'Thread' ? payload.id : null;
    const channelId = payload.__typename === 'Thread' ? payload.externalChannel.id : null;

    // In order to open text threads we need the entityId and entityName
    // We are using the bestEntity from the payload to get them for the sidebar
    const getEntityIdForSidebar = () => {
        if (props.entityPageId) {
            return props.entityPageId;
        }

        if (props.event.payload.bestEntity) {
            return props.event.payload.bestEntity.id ? props.event.payload.bestEntity.id : null;
        }

        return null;
    };
    const getEntityNameForSidebar = () => {
        if (props.entityPageName) {
            return props.entityPageName;
        }

        if (props.event.payload.bestEntity) {
            return props.event.payload.bestEntity.name ? props.event.payload.bestEntity.name : null;
        }

        return null;
    };

    const getOpenableModal = () => {
        switch (payload.__typename === 'Thread' ? payload.threadType : null) {
            case 'SMS':
                return OpenableModals.SMS_SIDEBAR;
            case 'CHAT':
            case 'MESSENGER':
            case 'INSTAGRAM':
            default:
                return OpenableModals.THREAD_SIDEBAR;
        }
    };

    const {onOpen} = useOpenableModal(getOpenableModal(), {
        entityId: getEntityIdForSidebar(),
        openedFromEntityName: getEntityNameForSidebar(),
        channelId,
        threadId,
    });

    /**
     * We need to include this check, even though we are only passing an event
     * with a payload.__typename of 'Thread' because Apollo doesn't generate a specific
     * enough type when using a union type (like the TimelineEventPayload).
     * See this issue for more detail:
     * https://github.com/apollographql/apollo-tooling/issues/1223
     */
    if (payload.__typename === 'Thread') {
        const displayMessage = payload.displayMessage || {};
        const displayMessageSender = displayMessage.isOutbound
            ? payload.displayMessage && payload.displayMessage.creatorUser
            : payload.bestEntity;
        const isMissingContactInfo = payload.externalChannel.status === 'MISSING_CONTACT_INFO';
        const canOpen = !isMissingContactInfo;

        // This relies on the assumption that we'll continue to use the origin as the actor for opt out change logs
        // Directly impacted by Inbox/Actions/SetChannelOptout.php
        const externalChannelStatus =
            actor.__typename !== 'User' ? payload.externalChannel.status : null;
        const actualActor =
            externalChannelStatus === 'OPTED_OUT' && payload.bestEntity && changeType === 'EDITED'
                ? payload.bestEntity
                : actor;

        const content = (
            <Stack spacing={8}>
                {isMissingContactInfo && (
                    <Body size='small' color='error'>
                        Unable to send messages because there are no associated people or companies.
                        Please add a person or company with a valid phone number to this lead to
                        start text messaging.
                    </Body>
                )}
                <span styleName='message'>
                    <Body size='small' isBold={true}>
                        {displayMessageSender ? displayMessageSender.name : 'Someone'}:&nbsp;
                    </Body>
                    {displayMessage.content}
                </span>
            </Stack>
        );

        const participants = payload.bestEntity
            ? [payload.bestEntity].map((entity) => ({
                  ...entity,
                  type: getApiTypeFromGraphQLTypename(entity.__typename),
              }))
            : undefined;

        const getStatusText = () => {
            switch (payload.threadType) {
                case 'CHAT':
                    return 'Web chat';
                case 'MESSENGER':
                    return 'Messenger';
                case 'INSTAGRAM':
                    return 'Instagram';
                case 'SMS':
                default:
                    return props.isPinnedThread ? 'Open thread' : 'Text thread';
            }
        };

        const getIconVariant = () => {
            switch (payload.threadType) {
                case 'CHAT':
                    return 'chat-thread';
                case 'MESSENGER':
                    return 'messenger-thread';
                case 'INSTAGRAM':
                    return 'instagram-thread';
                default:
                    return 'sms-thread';
            }
        };

        return (
            <TimelineEntry
                id={changeLogId}
                iconVariant={getIconVariant()}
                entryType={payload.__typename}
                timestamp={changeTime}
                actor={actualActor}
                participants={participants}
                status={{
                    primary: {
                        level: 'info--grey',
                        text: getStatusText(),
                    },
                }}
                content={content}
                changeType={changeType}
                hasGutterLine={!props.isPinnedThread}
                onClickOpen={canOpen ? onOpen : undefined}
                threadData={{
                    messageCount: payload.messages.totalCount || 0,
                    replyCount: payload.replyCount || 0,
                }}
                isPinnedThread={props.isPinnedThread}
                threadType={payload.__typename === 'Thread' ? payload.threadType : 'SMS'}
                externalChannelStatus={externalChannelStatus}
            />
        );
    }

    return null;
}
