/* @flow */
'no babel-plugin-flow-react-proptypes';

import * as React from 'react';
import classnames from 'classnames';
import {Link} from '../link';
import {Popover, type LocationEnum} from '../popover';
import './select-menu.css';

type Option = {
    label: string | React.Element<*>,
    icon?: React.Element<*>,
    key?: string,
    isDisabled?: boolean,
    handleSelect?: (option: Object, Event) => any,
    tooltip?: string,
};

// TODO (@ianvs): Use flow 0.42 type spread here when babel supports it
// Really, the submenu will not have yet another nested submenu, but I couldn't
// get flow to play nicely when making it an array of Options
export type SelectMenuOption = {
    label: string | React.Element<*>,
    icon?: React.Element<*>,
    key?: string,
    isDisabled?: boolean,
    variant?: 'primary' | 'secondary' | 'destructive',
    handleSelect?: (option: Object, Event) => any,
    // $FlowFixMe
    submenu?: Array<SelectMenuOption>,
    tooltipTextOnClose?: ?string,
};

type Props = {
    anchor: ?HTMLElement,
    options: Array<SelectMenuOption>,
    onClose: Function,
    location?: LocationEnum,
    customStyles?: Object,
    offsetX?: number,
    offsetY?: number,
};

type State = {
    submenuOption: ?Array<Option>,
};

export class SelectMenu extends React.Component<Props, State> {
    static defaultProps = {
        onClose: () => {},
    };

    state = {
        submenuOption: null,
    };

    render() {
        return (
            <Popover
                onBlur={this.props.onClose}
                location={this.props.location}
                anchor={this.props.anchor}
                customStyles={this.props.customStyles}
                noDefaultStyling={true}
                offsetX={this.props.offsetX}
                offsetY={this.props.offsetY}
            >
                <div styleName='container'>
                    {this.renderMenuItems(
                        this.state.submenuOption ? this.state.submenuOption : this.props.options
                    )}
                </div>
            </Popover>
        );
    }

    // $FlowFixMe
    renderMenuItems = (options: Array<SelectMenuOption>): React.Element<*>[] => {
        const isSubmenu = Boolean(this.state.submenuOption);

        return options.map((opt, index) => {
            const styleNames = classnames({
                'option-container--disabled': opt.isDisabled,
                'option-container--enabled': !opt.isDisabled,
                'option-container--submenu': isSubmenu,
                // This is the default style
                'option-container--secondary': !opt.variant || opt.variant === 'secondary',
                'option-container--destructive': opt.variant === 'destructive',
                'option-container--primary': opt.variant === 'primary',
            });

            // If the label is a Link, we want to remove the underline
            const label =
                typeof opt.label !== 'string' && opt.label.type === Link
                    ? React.cloneElement(opt.label, {preventUnderline: true})
                    : opt.label;

            return (
                <div
                    key={opt.key || index}
                    onClick={
                        !opt.isDisabled
                            ? (e) => this.handleSelect(opt, e)
                            : (e) => {
                                  e.stopPropagation();
                              }
                    }
                    styleName={styleNames}
                    title={opt.tooltip}
                >
                    {opt.icon ? <div styleName='icon-container'>{opt.icon}</div> : undefined}
                    {label}
                </div>
            );
        });
    };

    handleSelect = (opt: SelectMenuOption, e: Event) => {
        e.stopPropagation();
        if (opt.submenu) {
            this.setState({submenuOption: opt.submenu});

            return;
        }
        this.props.onClose(e, opt.tooltipTextOnClose);

        if (typeof opt.handleSelect === 'function') {
            opt.handleSelect(opt, e);
        }
    };
}
