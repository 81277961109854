/* @flow */

import * as React from 'react';

import {LinkedEntity} from 'shells/linked-entity';
import type {EventForTimeline} from '../types';
import {TimelineBanner} from './timeline-banner';

import './timeline-banner.css';

type Props = {event: EventForTimeline};

const USER_DRIVEN_EVENTS = ['published_time', 'sent_time', 'revoked_time'];
const ALL_EVENTS = ['published_time', 'sent_time', 'revoked_time', 'accepted_time'];

export function TimelineBannerQuote(props: Props) {
    const {event} = props;
    const {payload, changes} = event;

    const getAction = (
        attribute: string,
        quoteNumber: string,
        value: string,
        targetEntities: React.Element<any>
    ) => {
        const quoteNumberBold = <strong>{quoteNumber}</strong>;

        switch (attribute) {
            case 'published_time':
                return (
                    <span>
                        marked quote {quoteNumberBold} as ready for {targetEntities}
                    </span>
                );
            case 'sent_time':
                return (
                    <span>
                        sent quote {quoteNumberBold} to {targetEntities}
                    </span>
                );
            case 'revoked_time':
                return (
                    <span>
                        revoked quote {quoteNumberBold} for {targetEntities}
                    </span>
                );
            case 'accepted_time':
                return (
                    <span>
                        {targetEntities} accepted quote {quoteNumberBold} valued at&nbsp;
                        {value}
                    </span>
                );
            case 'archived_time':
                return (
                    <span>
                        archived quote {quoteNumberBold} for {targetEntities}
                    </span>
                );
            default:
                return <></>;
        }
    };

    /**
     * We need to include this check, even though we are only passing an event
     * with a payload.__typename of 'Quote' because Apollo doesn't generate a specific
     * enough type when using a union type (like the TimelineEventPayload).
     * See this issue for more detail:
     * https://github.com/apollographql/apollo-tooling/issues/1223
     */
    if (payload.__typename === 'Quote') {
        const change = changes.find((c) => ALL_EVENTS.includes(c.attribute));

        let targetEntities = [];

        if (payload.lead.accounts && payload.lead.accounts.primaryEdge) {
            const primaryAccount = payload.lead.accounts.primaryEdge.node;
            targetEntities.push(
                <LinkedEntity
                    type='accounts'
                    useEntityColor={true}
                    shouldClientRoute={true}
                    name={primaryAccount.name}
                    htmlUrl={primaryAccount.htmlUrl}
                    allowWrap={true}
                    displayInline={true}
                />
            );
        }

        if (payload.lead.contacts && payload.lead.contacts.primaryEdge) {
            const primaryContact = payload.lead.contacts.primaryEdge.node;
            targetEntities.push(
                <LinkedEntity
                    type='contacts'
                    useEntityColor={true}
                    shouldClientRoute={true}
                    name={primaryContact.name}
                    htmlUrl={primaryContact.htmlUrl}
                    allowWrap={true}
                    displayInline={true}
                />
            );
        }

        // Wrap up any primary accounts/contacts
        targetEntities =
            targetEntities.length > 0 ? (
                targetEntities.reduce((acc, entity, index) => {
                    if (index === 0) {
                        return entity;
                    }

                    return (
                        <>
                            {acc}, {entity}
                        </>
                    );
                })
            ) : (
                <LinkedEntity
                    type='leads'
                    useEntityColor={true}
                    shouldClientRoute={true}
                    name={payload.lead.name}
                    htmlUrl={payload.lead.htmlUrl}
                    allowWrap={true}
                    displayInline={true}
                    greenLeads={true}
                />
            );

        const displayNumber = payload.displayNumber;
        const attribute = change ? change.attribute : '';
        const totalValue = payload.total.formattedWithCents;

        // If this is a recipient driven event, we want to inject our own actor
        // so we can display both company and contacts
        const actor = USER_DRIVEN_EVENTS.includes(attribute) ? payload.creatorUser : null;
        const omitActor = actor === null;

        const action = getAction(attribute, displayNumber, totalValue, targetEntities);

        return (
            <TimelineBanner
                id={event.id}
                iconVariant='quote'
                timestamp={event.changeTime}
                actor={actor}
                action={action}
                omitActor={omitActor}
            />
        );
    }

    return <></>;
}
