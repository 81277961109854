/* @flow */

import * as React from 'react';
import classNames from 'classnames';

import './page-content-two-column.css';

type Props = {
    leftContent: React.Node,
    rightContent: React.Node,
    withoutOuterPadding?: boolean,
    overflowVisible?: boolean,
};

export function PageContentTwoColumn(props: Props) {
    return (
        <div
            styleName={classNames({
                container: true,
                'container--overflow-visible': props.overflowVisible,
            })}
        >
            <div
                styleName={classNames({
                    'left-container--without-outer-padding': props.withoutOuterPadding,
                    'left-container': !props.withoutOuterPadding,
                    'left-container--overflow-visible': props.overflowVisible,
                })}
            >
                {props.leftContent}
            </div>
            <div
                styleName={classNames({
                    'right-container--without-outer-padding': props.withoutOuterPadding,
                    'right-container': !props.withoutOuterPadding,
                    'right-container--overflow-visible': props.overflowVisible,
                })}
            >
                {props.rightContent}
            </div>
        </div>
    );
}
