/* @flow */

import type {LoginVariant} from './login-page';

export function validate(values: Object, variant: LoginVariant) {
    const errors = {};
    switch (variant) {
        case 'sign-in':
            if (!values.username || !values.username.trim()) {
                errors.username = 'Please enter an email address';
            }
            break;
        case 'password':
            if (!values.password || !values.password.trim()) {
                errors.password = 'Please enter your password';
            }
            break;
    }

    return errors;
}
