/* @flow */

import * as React from 'react';

import {Stack} from 'shells/layout';
import {ErrorMessage} from 'shells/form';
import {Line} from 'shells/divider';

import {GoogleOAuthButton} from '../../google-oauth/google-oauth-button';
import {MicrosoftOauthButton} from '../../microsoft-oauth/microsoft-oauth-button';

type Props = {|
    onGoogleSubmit: (string) => Promise<*>,
    onMicrosoftSubmit: (?string) => Promise<*>,
    onMicrosoftIdentityFailure: (?Error) => void,
    // If Google rejects the identity of the user,
    // i.e. before we even try to authenticate with Nutshell
    onGoogleIdentityFailure: () => void,
    googleErrorMessage: ?string,
    microsoftErrorMessage: ?string,
    microsoftOauthUrl: ?string,
|};

export function OauthSection(props: Props) {
    const [showGoogleLoginSpinner, setShowGoogleLoginSpinner] = React.useState<boolean>(false);
    const [submittingMicrosoftIdentity, setSubmittingMicrosoftIdentity] =
        React.useState<boolean>(false);

    const handleGoogleOAuthSuccess = (token: string) => {
        setShowGoogleLoginSpinner(true);

        props.onGoogleSubmit(token).then(() => {
            setShowGoogleLoginSpinner(false);
        });
    };

    const handleMicrosoftOAuthSuccess = (token) => {
        setSubmittingMicrosoftIdentity(true);
        props.onMicrosoftSubmit(token).then(() => {
            setSubmittingMicrosoftIdentity(false);
        });
    };

    const handleMicrosoftOAuthFailure = (error) => {
        setSubmittingMicrosoftIdentity(false);
        props.onMicrosoftIdentityFailure(error);
    };

    return (
        <div className='mt-24'>
            <Stack spacing={24}>
                <div>
                    <GoogleOAuthButton
                        variant='login'
                        onGoogleOAuthSuccess={handleGoogleOAuthSuccess}
                        onGoogleOAuthFailure={props.onGoogleIdentityFailure}
                        showLoadingSpinner={showGoogleLoginSpinner}
                    />
                </div>
                {props.googleErrorMessage && (
                    <div className='my-16'>
                        <ErrorMessage>{props.googleErrorMessage}</ErrorMessage>
                    </div>
                )}
                <MicrosoftOauthButton
                    variant='login'
                    oauthUrl={props.microsoftOauthUrl}
                    onAuthSuccess={handleMicrosoftOAuthSuccess}
                    onAuthFailure={handleMicrosoftOAuthFailure}
                    isSubmittingAuthResult={submittingMicrosoftIdentity}
                />
                {props.microsoftErrorMessage && (
                    <div className='my-16'>
                        <ErrorMessage>{props.microsoftErrorMessage}</ErrorMessage>
                    </div>
                )}
                <Line text='or' />
            </Stack>
        </div>
    );
}
