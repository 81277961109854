/* @flow */

import * as React from 'react';
import {connect} from 'react-redux';
import {reduxForm, formValueSelector} from 'redux-form';
import type {FormProps} from 'redux-form/lib/types.js.flow';

import {Subheader} from 'shells/typography';
import {ErrorMessage} from 'shells/form';

import {MFASidebarController} from '../../setup/mfa/mfa-sidebar-controller';
import {
    AUTHENTICATOR_MFA_REQUIRED_VALIDATION_MESSAGE,
    PHONE_MFA_REQUIRED_VALIDATION_MESSAGE,
    MFA_NOT_CONFIGURED_BUT_ENFORCED_MESSAGE,
    LOGIN_REDUX_FORM,
} from '../constants';

import {MFACodeForm} from './login-form/mfa-code-form';
import {SignInPage} from './login-form/sign-in-page';
import {PasswordLoginPage} from './login-form/password-login-page';
import {ChooseMethodPage} from './login-form/choose-method-page';
import type {LoginVariant} from './login-page';
import {validate} from './helpers';

import './login-form.css';

export const LOGIN_FORM_USERNAME_FIELD = 'username';
export const LOGIN_FORM_PASSWORD_FIELD = 'password';
export const LOGIN_FORM_REMEMBER_ME_FIELD = 'remember_me';

const emailSelector = formValueSelector(LOGIN_REDUX_FORM);

function mapStateToProps(state): StateProps {
    return {
        emailValue: emailSelector(state, 'username'),
    };
}

type OwnProps = {|
    onGoogleSubmit: (string) => Promise<*>,
    onMicrosoftSubmit: (?string) => Promise<*>,
    onMicrosoftIdentityFailure: (?Error) => void,
    // If Google rejects the identity of the user,
    // i.e. before we even try to authenticate with Nutshell
    onGoogleIdentityFailure: () => void,
    googleErrorMessage: ?string,
    microsoftErrorMessage: ?string,
    microsoftOauthUrl: ?string,
    ssoErrorMessage: ?string,
    initialValues: {
        username?: string,
        invalidCsrf?: boolean,
    },
    onContinue: (formValues: Object) => Promise<*>,
    onEmailLogin: (formValues: Object) => Promise<*>,
    variant: LoginVariant,
    setVariant: (variant: LoginVariant) => void,
    ssoRedirectUrl: ?string,
|};

type StateProps = {|
    emailValue?: string,
|};

type Props = {...OwnProps, ...StateProps, ...$Exact<FormProps>};

type State = {
    attemptedSignupWithExistingAccount: boolean,
    hasInvalidToken: boolean,
    isSessionTimeout: boolean,
    isEnteringMFACode: boolean,
    isConfiguringMFA: boolean,
    mfaType?: string,
    ssoErrorMessage?: string,
};

class LoginFormComponent extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            // We can make this more robust if we need to–currently treating URL
            // email param as a signup attempt with an existing account
            attemptedSignupWithExistingAccount: Boolean(props.initialValues.username),
            // Encompasses both CSRF and expired password reset token,
            // we show the same generic error message
            hasInvalidToken: Boolean(props.initialValues.invalidToken),
            isSessionTimeout: Boolean(props.initialValues.sessionTimeout),
            isEnteringMFACode: false,
            isConfiguringMFA: false,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (
            nextProps.error === AUTHENTICATOR_MFA_REQUIRED_VALIDATION_MESSAGE &&
            !this.props.error
        ) {
            this.setState({isEnteringMFACode: true, mfaType: 'authenticator'});
        } else if (nextProps.error === PHONE_MFA_REQUIRED_VALIDATION_MESSAGE && !this.props.error) {
            this.setState({isEnteringMFACode: true, mfaType: 'phone'});
        } else if (
            nextProps.error === MFA_NOT_CONFIGURED_BUT_ENFORCED_MESSAGE &&
            !this.props.error
        ) {
            this.setState({isConfiguringMFA: true});
        }
    }

    render() {
        let alternateMessage;
        if (this.state.attemptedSignupWithExistingAccount) {
            alternateMessage = (
                <Subheader>
                    It looks like you’ve already got an account with that email address! Please log
                    in to continue.
                </Subheader>
            );
        } else if (this.state.hasInvalidToken) {
            alternateMessage = (
                <ErrorMessage>
                    There was a problem authenticating and securing your request.
                </ErrorMessage>
            );
        } else if (this.state.isSessionTimeout) {
            alternateMessage = (
                <ErrorMessage>
                    Your session timed out due to your company’s timeout policy
                </ErrorMessage>
            );
        } else if (this.props.ssoErrorMessage) {
            alternateMessage = <ErrorMessage>{this.props.ssoErrorMessage}</ErrorMessage>;
        }

        return (
            <React.Fragment>
                {this.state.isConfiguringMFA ? <MFASidebarController /> : undefined}
                {this.state.isEnteringMFACode ? (
                    <MFACodeForm
                        onSubmit={this.props.handleSubmit(this.props.onEmailLogin)}
                        onBack={() => {
                            location.reload();
                        }}
                        error={this.props.error}
                        valid={this.props.valid}
                        submitting={this.props.submitting}
                        submitFailed={this.props.submitFailed}
                        mfaType={this.state.mfaType}
                    />
                ) : undefined}
                {!this.state.isEnteringMFACode && !this.state.isConfiguringMFA ? (
                    <>
                        {this.props.variant === 'sign-in' && (
                            <SignInPage
                                alternateMessage={alternateMessage}
                                handleSubmit={this.props.handleSubmit(this.props.onContinue)}
                                submitting={this.props.submitting}
                                valid={this.props.valid}
                                emailValue={this.props.emailValue}
                                submitFailed={this.props.submitFailed}
                                error={this.props.error}
                                onGoogleSubmit={this.props.onGoogleSubmit}
                                onMicrosoftSubmit={this.props.onMicrosoftSubmit}
                                onMicrosoftIdentityFailure={this.props.onMicrosoftIdentityFailure}
                                // If Google rejects the identity of the user,
                                // i.e. before we even try to authenticate with Nutshell
                                onGoogleIdentityFailure={this.props.onGoogleIdentityFailure}
                                googleErrorMessage={this.props.googleErrorMessage}
                                microsoftErrorMessage={this.props.microsoftErrorMessage}
                                microsoftOauthUrl={this.props.microsoftOauthUrl}
                            />
                        )}
                        {this.props.variant === 'password' && (
                            <PasswordLoginPage
                                handleSubmit={this.props.handleSubmit(this.props.onEmailLogin)}
                                submitting={this.props.submitting}
                                valid={this.props.valid}
                                emailValue={this.props.emailValue}
                                submitFailed={this.props.submitFailed}
                                error={this.props.error}
                            />
                        )}
                        {this.props.variant === 'choose-method' && (
                            <ChooseMethodPage
                                emailValue={this.props.emailValue}
                                ssoRedirectUrl={this.props.ssoRedirectUrl}
                                setVariant={this.props.setVariant}
                            />
                        )}
                    </>
                ) : undefined}
            </React.Fragment>
        );
    }
}

const connector = connect<Props, {|...OwnProps, ...$Exact<FormProps>|}, _, _, _, _>(
    mapStateToProps
);

export const LoginForm = reduxForm({
    form: LOGIN_REDUX_FORM,
    validate: (values, props) => validate(values, props.variant),
})(connector(LoginFormComponent));
