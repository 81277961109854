/* @flow */

import type {QuoteDisplayStatus} from 'nutshell-graphql-types';
import type {StatusLabelVariant} from '../../quotes/status/status-label';

export const getDisplayStatus = (quote: QuoteDisplayStatus): StatusLabelVariant => {
    switch (quote) {
        case 'SENT':
            return 'PUBLISHED';
        default:
            return quote;
    }
};
