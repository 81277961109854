/* @flow */

import React from 'react';

type Props = {
    width?: number,
};

export const NutshellInstagramBubble = ({width = 200}: Props) => {
    return (
        <img
            alt='Nutshell + Instagram icon'
            style={{width}}
            src='/include/images/nutshell-instagram-bubble.png'
        />
    );
};
