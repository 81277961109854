/* @flow */

import * as React from 'react';

import {Quote as QuoteIllustration} from 'shells/illustrations';

import {Body} from '../typography/';
import {Button} from '../button';
import {Link} from '../link';
import {Stack} from '../layout/stack';
import {BulletedList} from '../bulleted-list';
import {TwoPaneModal} from './two-pane-modal';

import './quote-info-modal.css';

type Props = {
    isOpen: boolean,
    onClose: () => void,
};

export function QuoteInfoModal(props: Props) {
    const bullets = [
        'Create quotes with your company’s logo, contact information and any other helpful content',
        'Send your quotes via a link so you can track views — or download and send PDFs',
        'Track the progress of quotes from draft to ready to accepted',
    ];

    return (
        <TwoPaneModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            maxHeight={600}
            minWidth={820} // Make title fit on one line
            closeButton={false}
            leftSection={
                <div styleName='modal-left-section'>
                    <QuoteIllustration width={300} />
                </div>
            }
            rightSection={
                <>
                    <div className='pad-32 align-self-center'>
                        <Stack spacing={16}>
                            <Body size='xlarge' isBold={true}>
                                Create snazzy quotes with one click
                            </Body>
                            <Body>
                                Use Nutshell Quotes to create and send quotes, proposals or
                                estimates to your leads. They’ll be automatically filled with
                                contact information, products and discounts.
                            </Body>
                            <BulletedList
                                bullets={bullets}
                                greyBullets={true}
                                marginBottom={0}
                                lessSpacing={true}
                            />
                            <div className='mt-32'>
                                <Link
                                    href='https://support.nutshell.com/en/articles/10616834-set-up-and-use-nutshell-quotes-to-create-share-and-manage-quotes-for-your-leads'
                                    variant='primary'
                                    target='_blank'
                                >
                                    Learn more on our knowledge base
                                </Link>
                            </div>
                        </Stack>
                        <div styleName='done-button'>
                            <Button onClick={props.onClose} variant='primary'>
                                Done
                            </Button>
                        </div>
                    </div>
                </>
            }
        />
    );
}
