/* env node */
/* eslint-disable import/no-commonjs */

/*
 * This is `require'd` into our post css config, so it needs to be old-skool commonjs
 */

const palette = {
    '--orange-dk': '#bf4200', // user
    '--orange': '#fe5800', // Brand orange
    '--orange-lt': '#ffdecc',
    '--orange-xlt': '#faf0e6',
    '--orange-attention': '#ea7134',

    '--grey-dk': '#211712',
    '--grey': '#4d4540',
    '--grey-lt': '#7a7370',

    '--icon-primary': '#4d4540',
    '--icon-primary-hovered': '#211712',

    '--icon-primary-action': '#d4d1cf',
    '--icon-primary-action-hovered': '#61b2de',

    '--icon-secondary': '#d4d1cf',
    '--icon-secondary-dk': '#7a7370',

    '--button-secondary': '#ebe8e8',
    '--button-secondary-dk': '#dbd9d9',

    '--form-border': '#d4d1cf',
    '--label': '#4d4540', // Color for form labels

    '--black': '#000000',
    '--white': '#ffffff',
    '--off-white-dk': '#ebe8e8',
    '--off-white-md': '#ebe8e8',
    '--off-white': '#f7f7f7',
    '--off-white-transparent': 'rgba(247, 247, 247, 0)',

    '--zebra-stripe': '#f7f7f7',

    '--success-bg': '#ecf9ef',
    '--success': '#0b8d6c',
    '--info-bg': '#eaf5fb',
    '--info': '#337da8',
    '--warning-bg': '#fff5e5',
    '--warning': '#ea7134',
    '--alert-bg': '#fff7f7',
    '--alert': '#bf2436',

    // Report colors
    '--report-green': '#5ccf73',
    '--report-red': '#ff596b',
    '--report-blue': '#61b2de',
    '--report-orange': '#ff7833',
    '--report-orange-bg': '#fff7f5',
    '--report-navy': '#3d405c',

    '--plum-dk': '#3d3b8a', // Company text
    '--plum': '#3d3b8a', // Company
    '--plum-xlt': '#e9e9f6',
    '--company': '#514fb8', // Company

    '--onboarding-help-dk': '#3d3b8a', // Originally plum-dk, currently company-dk
    '--onboarding-help': '#514fb8', // Originally plum
    '--onboarding-help-lt': '#7373c7',

    '--plum-lt': '#dbdbf2',
    '--plum-bg': '#e9e9f6',

    // Blue
    '--navy-dk': '#337da8', // person text
    '--navy': '#337da8', // Person
    '--navy-bg': '#f7fafc',
    '--person': '#337da8', // Person
    // Navy lt is deprecated
    '--navy-lt': '#d9edf7',
    '--blue-dk': '#337da8', // Action primary hovered
    '--blue': '#3b9eD7', // action primary
    '--blue-lt': '#d9edf7',
    '--blue-xlt': '#e8f4fa',
    '--blue-bg': '#d9edf7', // SAME AS ABOVE
    '--blue-bg-lt': '#eff5f7',
    '--nutshell-pro': '#4f86c6',
    '--ai-blue': '#0c0f32',
    '--ai-hover': '#0a0d2b',
    '--support': '#3d3b8a',
    '--support-hover': '#292967',

    '--green-dk': '#2e9942', // success hovered
    '--green': '#33c252', // success
    // not used yet
    '--lead': '#2e9942', // Lead
    '--lead-hovered': '#0b8d6c', // Lead hovered
    '--green-lt': '#d6f2de',
    '--green-xlt': '#e4f6e9',
    '--green-bg': '#f7fcf7',

    '--rose-dk': '#bf2436', // Error hovered
    '--rose': '#ff2e47', // Error
    '--rose-lt': '#ffd6d9',
    '--rose-xlt': '#fff7f7',
    '--red': '#ff2e47',
    '--red-lt': '#ffd6d9',
    '--sidebar-red': '#0c0f33',
    '--wallboard-red': '#fff7f7',

    // use when updating wallboard

    '--product': '#bd9633',
    '--product-bg': '#bd9633',
    '--product-bg-hovered': '#fac942',

    '--brown-dk': '#bd9633',
    '--brown': '#fff0c5',
    '--brown-lt': '#fff5d9', // product box

    '--yellow': '#fac947',
    '--yellow-lt': '#fff5d9',
    '--yellow-bg': '#fffcf7',

    '--dotcom-purple': '#660347',
    '--dotcom-teal': '#62d4ca',

    // Tiny intro colors
    '--tiny-intro-login': '#d9edf7',
    '--tiny-intro-signup': '#d9edf7',
    '--tiny-intro-teal': '#d9edf7',
    '--tiny-intro-teal-transparent':
        'rgba(217, 237, 247, 0)' /* same as above, but for a fade in dynamic-cards.css */,
    '--tiny-intro-off-white': '#d9edf7',

    '--activity': '#bf2436', // Activity
    '--task': '#bd9633', // Task

    // Focus states
    '--focus-blue': '#065289',

    // Skeleton placeholder view
    '--skeleton-grey': '#f1f1f1',

    // Tag colors
    '--tag-grey-lt': '#EBE8E8',
    '--tag-grey': '#D4D1CF',
    '--tag-red': '#FFB2BB',
    '--tag-red-lt': '#FFE5E7',
    '--tag-orange': '#FFCCB2',
    '--tag-orange-lt': '#FFEEE5',
    '--tag-yellow': '#FDE9B4',
    '--tag-yellow-lt': '#FFF8E5',
    '--tag-green': '#C4EECC',
    '--tag-green-lt': '#ECF9EF',
    '--tag-blue': '#C0E0F2',
    '--tag-blue-lt': '#EAF5FB',
    '--tag-purple': '#C8C8E9',
    '--tag-purple-lt': '#ECECF8',

    // 2025 navigation colors
    '--navy-dk-new': '#404372',
    '--navy-lt-new': '#5977a4',
    '--navy-xlt-new': '#94d1fe',
    '--sidebar-secondary': '#242854',
    '--navy-bg-new': '#333766',
    '--navy-bg-active-new': '#5c5f87',
    '--secondary-sidebar-hover-blue': '#3F4372',
    '--rcfx-sidebar': '#1C2C48',
    '--rcfx-sidebar-hover': '#1C293F',
    '--rcfx-sidebar-primary-item': '#142034',
    '--rcfx-sidebar-active': '#0C57AD',

    // Quote & Sign colors
    '--quote-signature-orange': '#e39138',
    '--quote-toolbar-background': '#222f3e',
    '--quote-toolbar-button-background': '#394452',

    // Facebook colors
    '--messenger-blue': '#3B5998',
    '--instagram-pink': '#DD2A7B',

    '--zoom-blue': '#0b5cff',

    '--sidebar-list-loading': '#333557',
};

const cssColors = Object.assign({}, palette, {
    /* ============================ */
    /* = Semantic colors          = */
    /* ============================ */

    '--color-list-hovered': palette['--blue-bg'],
    '--color-list-selected': '#e8f4fb',
    '--color-overdue': '#bf2436',
    '--color-error': palette['--rose'],
    '--color-warning': '#bf4200',
    '--color-success': palette['--success'],
    '--color-modal-overlay': 'hsla(234, 18%, 25%, 0.67)',
    '--color-flash-banner-error-text': 'hsl(353, 68%, 45%)',
    '--color-flash-banner-error-background': 'hsl(356, 100%, 92%)',
    '--placeholder': '#a6a3a1',
    '--color-text-hot': '#fe5800',
    '--color-text-accounts': palette['--plum-dk'],
    '--color-text-contacts': palette['--navy-dk'],
    '--color-text-users': palette['--orange-dk'],
    '--color-background-hot': '#fe5800',
    '--color-banner-border-orange': '#ea7134',

    /* ============================================= */
    /* = New semantic colors 2020 rebrand          = */
    /* ============================================= */
    '--sidebar-primary': '#0c0f33',
    '--sidebar-hovered': '#cfcfd6',
    '--color-upgrade': '#0c0f33',
});

module.exports = cssColors;
