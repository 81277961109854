/* @flow */

import * as React from 'react';
import classNames from 'classnames';

import {Body, type TextSize} from '../typography';

import './empty-state-small.css';

type Props = {|
    icon?: any,
    title: string,
    titleSize?: TextSize,
    subtitle?: React.Node | string,
    subtitleSize?: TextSize,
    cta?: React.Node,
    // If true, the content container will be flexible in height instead of a fixed min-height
    isFlexibleHeight?: boolean,
    iconSpacing?: number,
|};

export function EmptyStateSmall(props: Props) {
    const iconSpacing = props.iconSpacing || 32;

    return (
        <div className='flex flex-dir-col align-center justify-center'>
            <div
                styleName={classNames({
                    'content-container-flexible': props.isFlexibleHeight,
                    'content-container-fixed': !props.isFlexibleHeight,
                })}
                style={{gap: iconSpacing}}
            >
                {props.icon}
                <div styleName='empty-text'>
                    <Body isBold={true} color='dark' size={props.titleSize || 'large'}>
                        {props.title}
                    </Body>
                    {props.subtitle && (
                        <Body size={props.subtitleSize || 'normal'}>{props.subtitle}</Body>
                    )}
                </div>
            </div>
            {props.cta}
        </div>
    );
}
