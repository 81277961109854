/* @flow */

import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Keyboard from './icon-keyboard.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Keyboard {...validProps} />;
}
SvgComponent.iconName = 'keyboard';

export const KeyboardIcon = svgIconWrapper(SvgComponent);
