/* @flow */

import * as React from 'react';

import {LinkedEntity} from 'shells/linked-entity';
import type {EventForTimeline} from '../types';
import {TimelineBanner} from './timeline-banner';

import './timeline-banner.css';

type Props = {event: EventForTimeline};

export function TimelineBannerQuoteView(props: Props) {
    const {event} = props;
    const {payload} = event;

    /**
     * We need to include this check, even though we are only passing an event
     * with a payload.__typename of 'QuoteView' because Apollo doesn't generate a specific
     * enough type when using a union type (like the TimelineEventPayload).
     * See this issue for more detail:
     * https://github.com/apollographql/apollo-tooling/issues/1223
     */
    if (payload.__typename === 'QuoteView') {
        let targetEntities = [];
        const lead = payload.quote.lead;

        if (lead && lead.accounts.primaryEdge) {
            const primaryAccount = lead.accounts.primaryEdge.node;
            targetEntities.push(
                <LinkedEntity
                    type='accounts'
                    useEntityColor={true}
                    shouldClientRoute={true}
                    name={primaryAccount.name}
                    htmlUrl={primaryAccount.htmlUrl}
                    allowWrap={true}
                    displayInline={true}
                />
            );
        }

        if (lead.contacts && lead.contacts.primaryEdge) {
            const primaryContact = lead.contacts.primaryEdge.node;
            targetEntities.push(
                <LinkedEntity
                    type='contacts'
                    useEntityColor={true}
                    shouldClientRoute={true}
                    name={primaryContact.name}
                    htmlUrl={primaryContact.htmlUrl}
                    allowWrap={true}
                    displayInline={true}
                />
            );
        }

        // Wrap up any primary accounts/contacts
        targetEntities =
            targetEntities.length > 0 ? (
                targetEntities.reduce((acc, entity, index) => {
                    if (index === 0) {
                        return entity;
                    }

                    return (
                        <>
                            {acc}, {entity}
                        </>
                    );
                })
            ) : (
                <LinkedEntity
                    type='leads'
                    useEntityColor={true}
                    shouldClientRoute={true}
                    name={payload.quote.lead.name}
                    htmlUrl={payload.quote.lead.htmlUrl}
                    allowWrap={true}
                    displayInline={true}
                    greenLeads={true}
                />
            );

        const action = (
            <span>
                {targetEntities} viewed quote <strong>{payload.quote.displayNumber}</strong>
            </span>
        );

        return (
            <TimelineBanner
                id={event.id}
                iconVariant='quote'
                timestamp={event.changeTime}
                actor={null}
                action={action}
                omitActor={true}
            />
        );
    }

    return <></>;
}
