/* @flow */

import * as React from 'react';
import classnames from 'classnames';

import './main-content-container.css';

/*
This component provides a consistent outer container for components rendered
to the right of a navigation sidebar, inside a `page-main-content` div
(e.g. in the settings page)
 */
export class MainContentContainer extends React.PureComponent<{
    children?: any,
    includesHeader: boolean,
    whiteBackground?: boolean,
    minWidth?: number,
    // Mostly used on settings pages where the outer sidebar needs
    // to dictate the scroll container height.
    preventInnerScroll?: boolean,
    darkBackground?: boolean,
}> {
    static defaultProps = {
        includesHeader: true,
    };

    render() {
        const innerContainerStyleName = classnames({
            'container-inner': true,
            'container-inner--white': this.props.whiteBackground,
            'container-inner--no-scroll': this.props.preventInnerScroll,
            'container-inner--dark': this.props.darkBackground,
        });

        return (
            <div styleName={this.props.includesHeader ? 'container' : 'container--no-header'}>
                <div
                    style={this.props.minWidth ? {minWidth: this.props.minWidth} : undefined}
                    styleName={innerContainerStyleName}
                >
                    {this.props.children}
                </div>
            </div>
        );
    }
}
