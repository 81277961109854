/* @flow */

import * as React from 'react';
import {Helmet} from 'react-helmet';
import {colors} from 'shells/colors';
import {headTitle} from 'shells/document';
import {MarketingHeader, MarketingBody} from 'shells/typography';
import {PreAuthLayout} from 'shells/layout';
import {Link} from 'shells/link';
import './old-browser-page.css';

export function OldBrowserPage() {
    return (
        <PreAuthLayout backgroundColor={colors.navyLt}>
            <Helmet>
                <title>{headTitle('Unsupported browser')}</title>
            </Helmet>
            <div>
                <MarketingHeader>
                    To give you the best experience, Nutshell requires a modern browser.{' '}
                </MarketingHeader>
                <MarketingBody>
                    Please see our full list of{' '}
                    <Link
                        href='https://support.nutshell.com/en/articles/8428994-using-nutshell-browser-and-app-compatibility'
                        variant='primary'
                        size='big'
                    >
                        supported browsers
                    </Link>
                    , or try one of these alternatives:
                </MarketingBody>
                <div styleName='browser-list'>
                    <a href='http://www.google.com/chrome/'>
                        <img alt='Chrome logo' src='/include/images/old-browser-chrome.png' />
                        <MarketingBody>Google Chrome</MarketingBody>
                    </a>
                    {navigator.platform.includes('Mac') ? (
                        <a href='http://www.apple.com/safari/'>
                            <img alt='Safari logo' src='/include/images/old-browser-safari.png' />
                            <MarketingBody>Apple Safari</MarketingBody>
                        </a>
                    ) : null}
                    {navigator.platform.includes('Win') ? (
                        <a href='https://www.microsoft.com/en-us/windows/microsoft-edge'>
                            <img alt='chrome logo' src='/include/images/old-browser-edge.png' />
                            <MarketingBody>Microsoft Edge</MarketingBody>
                        </a>
                    ) : null}
                    <a href='http://www.mozilla.org/firefox/'>
                        <img alt='chrome logo' src='/include/images/old-browser-firefox.png' />
                        <MarketingBody>Firefox</MarketingBody>
                    </a>
                </div>
            </div>
        </PreAuthLayout>
    );
}
