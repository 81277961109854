/* @flow */

import * as React from 'react';

import type {EventFragmentSparse} from 'nutshell-graphql-types';

import {appHistory} from '../../../history';
import {TimelineBanner} from './timeline-banner';

import './timeline-banner.css';

type Props = {|event: EventFragmentSparse|};

export function TimelineBannerOutreachSent(props: Props) {
    const {event} = props;

    if (
        event.payload &&
        (event.payload.__typename === 'Email' || event.payload.__typename === 'Message')
    ) {
        const title = getTitle(event);
        const recipient = getRecipient(event);
        const htmlUrlPath = getHtmlUrlPath(event);

        const action = (
            <span>
                was sent&nbsp;
                {title ? (
                    <span
                        styleName='target--button'
                        role='button'
                        tabIndex='0'
                        onClick={() => appHistory.push(htmlUrlPath)}
                    >
                        {title}
                    </span>
                ) : (
                    <span>
                        a Nutshell Campaigns{' '}
                        {event.payload.__typename === 'Email' ? 'email' : 'text message'}
                    </span>
                )}
            </span>
        );

        const iconVariant =
            event.payload.__typename === 'Email' ? 'email-outreach-sent' : 'sms-outreach-sent';

        return (
            <TimelineBanner
                id={event.id}
                iconVariant={iconVariant}
                timestamp={event.changeTime}
                // $FlowIgnore it is proving impossible to get these types to line up perfectly
                actor={recipient}
                action={action}
            />
        );
    }

    return null;
}

function getTitle(event: EventFragmentSparse) {
    if (event.payload.__typename === 'Email') {
        // The title is the name of the edition but if we don't have that for some reason,
        // we default to the subject of the email
        return event.payload.edition ? event.payload.edition.name : event.payload.subject;
    }

    if (event.payload.__typename === 'Message' && event.payload.edition) {
        return event.payload.edition.name;
    }

    return '';
}

function getRecipient(event: EventFragmentSparse) {
    if (event.payload.__typename === 'Email') {
        /**
         * In this case, we want to display the first recipient in the 'to' array
         * as the actor so the banner displays properly
         */
        return event.payload.recipients &&
            event.payload.recipients.length &&
            event.payload.recipients[0].avatarEntity &&
            event.payload.recipients[0].avatarEntity.__typename === 'Contact'
            ? event.payload.recipients[0].avatarEntity
            : undefined;
    }

    if (event.payload.__typename === 'Message') {
        return event.payload.thread.bestEntity;
    }

    return undefined;
}

function getHtmlUrlPath(event: EventFragmentSparse) {
    if (event.payload.__typename === 'Email') {
        return `#${event.payload.htmlUrlPath}`;
    }

    if (event.payload.__typename === 'Message' && event.payload.edition) {
        return event.payload.edition.htmlUrlPath;
    }

    return '';
}
