/* @flow */

import * as React from 'react';

import {Button, type ButtonVariant} from 'shells/button';
import {LoadingIcon} from 'shells/icon';

import '../login-form.css';

type Props = {|
    bttnVariant: ButtonVariant,
    submitting?: boolean,
    onClick?: () => void,
    children?: React.Node,
|};

export function LoginFlowButton(props: Props) {
    return (
        <div styleName='button submit'>
            <Button
                variant={props.bttnVariant}
                type='submit'
                size='xlarge'
                disabled={props.submitting}
                isFullWidth={true}
                onClick={props.onClick}
            >
                <div className='flex full-width full-height align-center justify-center gap-8'>
                    {props.children ? (
                        props.children
                    ) : (
                        <>
                            {props.submitting ? 'Logging in…' : 'Log in'}
                            {props.submitting && (
                                <div>
                                    <LoadingIcon fill='#fff' size={29} />
                                </div>
                            )}
                        </>
                    )}
                </div>
            </Button>
        </div>
    );
}
