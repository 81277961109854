/* @flow */

import * as React from 'react';
import {useMutation} from '@apollo/react-hooks';
import {components, type OptionProps, type SingleValueProps} from 'react-select';

import type {
    GetAudiencesBasic as GetAudiencesQuery,
    SelectCreateAudience as SelectCreateAudienceMutation,
    SelectCreateAudienceVariables as SelectCreateAudienceMutationVariables,
} from 'nutshell-graphql-types';

import {OptionWithCount, type ListOptionWithCount} from 'shells/select-option-components';

import {appHistory} from '../../../history';
import {useGetSessionUser} from '.././use-get-session-user';
import {GraphQLSelect} from './graphql-select';
import GET_AUDIENCES from './queries/get-audiences.graphql';
import CREATE_AUDIENCE from './mutations/create-audience.graphql';

type Props = {
    selectedId: ?string,
    options: ListOptionWithCount[],
    onChange: (newValue: ListOptionWithCount) => any,
    refetch?: () => Promise<*>,
    updateOptions?: (newOption: ListOptionWithCount) => void,
    onClearValue?: () => void,
    labelKey?: string,
    valueKey?: string,
    autoFocus?: boolean,
    autoBlur?: boolean,
    isLoading?: boolean,
    noResultsText?: string,
    styles?: Object,
    menuPortalTarget?: HTMLElement,
    defaultsToFirstItem?: boolean,
    isCreateable?: boolean,
    hasError?: ?boolean,
};

export const GraphQLSelectAudiences = (props: Props) => {
    const [createAudience] = useMutation<
        SelectCreateAudienceMutation,
        SelectCreateAudienceMutationVariables,
    >(CREATE_AUDIENCE);

    const {user} = useGetSessionUser();

    return (
        <GraphQLSelect
            {...props}
            onChange={(newValue: ListOptionWithCount) => {
                if (!props.options.find((option) => option.value === newValue.value)) {
                    const tempNewOption = {
                        value: newValue.value,
                        label: newValue.label,
                        count: 0,
                    };
                    props.updateOptions ? props.updateOptions(tempNewOption) : undefined;

                    // This is a created value, need to run our mutation.
                    createAudience({
                        variables: {
                            audience: {audience: {name: newValue.label}},
                        },
                        update: (proxy, mutationResults) => {
                            // Read the data from our cache for this query.
                            const cacheResult: ?GetAudiencesQuery = proxy.readQuery({
                                query: GET_AUDIENCES,
                            });

                            if (!cacheResult || !cacheResult.audiences) {
                                return;
                            }

                            const newAudience =
                                mutationResults &&
                                mutationResults.data &&
                                mutationResults.data.audienceCreate &&
                                mutationResults.data.audienceCreate.audience;

                            if (!newAudience) return;

                            proxy.writeQuery({
                                query: GET_AUDIENCES,
                                data: {
                                    audiences: {
                                        ...cacheResult.audiences,
                                        edges: [
                                            {
                                                cursor: newAudience.id,
                                                node: newAudience,
                                                __typename: 'AudiencesEdge',
                                            },
                                        ].concat(cacheResult.audiences.edges),
                                    },
                                },
                            });

                            const newAudienceValue = {
                                value: newAudience.id,
                                label: newAudience.name,
                                count: newAudience.membershipStats.totalCount.value,
                            };

                            // Update our selected item to the new item
                            props.onChange(newAudienceValue);

                            // Update the audience options to include the new one immediately
                            props.updateOptions ? props.updateOptions(newAudienceValue) : undefined;

                            // Refetch the audience options from the server
                            props.refetch ? props.refetch() : undefined;
                        },
                    });
                } else {
                    return props.onChange(newValue);
                }
            }}
            isLoading={props.isLoading}
            openMenuOnFocus={true}
            noResultsText='No audiences were found'
            placeholder='Select or create an audience'
            valueKey='value'
            labelKey='label'
            components={{
                Option: OptionComponent,
                SingleValue: Value,
            }}
            models={props.options}
            hasError={props.hasError}
            footerButton={
                props.isCreateable && user && user.permissions.canAccessMarketing
                    ? {
                          label: 'Manage audiences',
                          onClick: () => {
                              appHistory.push('/email-marketing/audiences');
                          },
                      }
                    : undefined
            }
        />
    );
};

const Value = (props: SingleValueProps) => {
    const spreadableProps: $Exact<OptionProps> = (props: any);
    const contactDescriptor = props.data.count === 1 ? 'person' : 'people';
    const title = `${props.data.count} ${contactDescriptor} in this audience`;

    return (
        // $FlowFixMe upgrading Flow to v0.92.1 on web
        <components.SingleValue {...spreadableProps}>
            <OptionWithCount
                title={title}
                count={props.data.count}
                label={props.data.label}
                isFx={props.data.isFx}
                isSyncing={props.data.isSyncing}
            />
        </components.SingleValue>
    );
};

const OptionComponent = (props: OptionProps) => {
    // This is a hack to get around limitations in our old version of flow
    const spreadableProps: $Exact<OptionProps> = (props: any);
    const contactDescriptor = props.data.count === 1 ? 'person' : 'people';
    const title = `${props.data.count} ${contactDescriptor} in this audience`;

    return (
        // $FlowFixMe upgrading Flow to v0.92.1 on web
        <components.Option {...spreadableProps}>
            <OptionWithCount
                title={title}
                count={props.data.count}
                label={props.data.label}
                isFx={props.data.isFx}
                isSyncing={props.data.isSyncing}
            />
        </components.Option>
    );
};
